import { Link } from 'react-router-dom';
import { MDMProjectResult } from '../types';

type Props = {
  results: Array<MDMProjectResult>;
};

export function MDMProjectResultsTable({ results }: Props) {
  return (
    <div>
      <table className="min-w-full">
        <thead>
          <tr className="border-b">
            <th className="text-left py-2 text-sm font-medium">Executed on</th>
            <th className="text-left py-2 text-sm font-medium">Review status</th>
            <th className="text-right py-2 text-sm font-medium"></th>
          </tr>
        </thead>
        <tbody>
          {results?.map((result) => (
            <tr key={result.id} className="border-b">
              <td className="py-2 text-sm">{result.runDate.toUTCString()}</td>
              <td className="py-2 text-sm">{result.status}</td>
              <td className="py-2 text-right">
                <Link
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 rounded text-sm"
                  to={`/demo/mdmv2/${result.mdmProjectId}/result/${result.id}`}
                >
                  Review
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
