import clsx from 'clsx';
import { useState } from 'react';
import { EnrichmentDataTableHeader, EnrichmentDataTableRow } from './types';
import { ViewSourceEnrichmentResultModal } from './ViewSourceEnrichmentResultModal';
import { useImportedData } from './ImportedData';
import { useActiveFileEnrichmentResults, useProductEnrichment } from './Enrichment';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { RowEnrichmentResultState } from './enrichmentResultsReducer';

type Props = {
  headers: EnrichmentDataTableHeader[];
  rows: EnrichmentDataTableRow[];
  enrichmentDisabled: boolean;
};

export function EnrichmentDataTable(props: Props) {
  const [viewSourceEnrichmentResultModal, setViewSourceEnrichmentResultModal] =
    useState<RowEnrichmentResultState | null>(null);
  const { updateFieldValue, regenerateField } = useProductEnrichment();
  const { updateActiveHeaderKind, activeFileName } = useImportedData();
  const { enrichmentResults } = useProductEnrichment();
  useEnrichmentConfig();
  useActiveFileEnrichmentResults(activeFileName || undefined);

  return (
    <div className="my-4 w-full shadow-md border-2 border-gray-300 rounded-md overflow-x-auto">
      <ViewSourceEnrichmentResultModal
        result={viewSourceEnrichmentResultModal}
        isOpen={!!viewSourceEnrichmentResultModal}
        onClose={() => {
          setViewSourceEnrichmentResultModal(null);
        }}
        onUpdateFieldValue={(fieldName, value, groupName) => {
          if (activeFileName) {
            const rowId = Object.keys(enrichmentResults[activeFileName]).find(
              (key) => enrichmentResults[activeFileName][key] === viewSourceEnrichmentResultModal
            );
            if (rowId) {
              updateFieldValue(activeFileName, rowId, groupName, fieldName, value);
            }
          }
        }}
        onRegenerateField={async (fieldName, groupName) => {
          if (activeFileName) {
            const rowId = Object.keys(enrichmentResults[activeFileName]).find(
              (key) => enrichmentResults[activeFileName][key] === viewSourceEnrichmentResultModal
            );
            if (rowId) {
              await regenerateField(activeFileName, rowId, groupName, fieldName);
            }
          }
        }}
      />
      <table className="w-full text-left">
        <thead>
          <tr>
            {props.headers.map((header) => (
              <th
                className={clsx(
                  'p-4 whitespace-nowrap cursor-pointer',
                  header.kind === 'identifier' ? 'text-green-600' : ''
                )}
                key={header.id}
                onClick={() => {
                  const newKind = header.kind === 'identifier' ? null : 'identifier';
                  updateActiveHeaderKind(header.id, newKind);
                }}
              >
                {header.name}
              </th>
            ))}
            <th className="p-4 whitespace-nowrap sticky right-0">
              <div className="font-medium text-left sr-only">Actions</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.rows.map((row, rowIdx) => {
            return (
              <tr className={clsx(rowIdx % 2 === 0 ? 'bg-gray-100' : '')} key={row.id}>
                {row.cells.map((cell) => (
                  <td key={cell.id} className={clsx('px-4 py-2', rowIdx % 2 === 0 ? 'bg-gray-100' : 'bg-gray-50')}>
                    {cell.value.slice(0, 50)}
                    {cell.value.length > 50 ? '...' : ''}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
