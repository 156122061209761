import { z } from 'zod';

export const DatasetMetadataSchema = z.object({
  mdm: z.object({
    fieldMapping: z.record(
      z.object({
        purpose: z.string(),
        dtype: z.string()
      })
    )
  })
});
export type DatasetMetadata = z.infer<typeof DatasetMetadataSchema>;

export const DatasetSchema = z.object({
  id: z.number(),
  tenantId: z.string(),
  name: z.string(),
  description: z.string(),
  filePath: z.string(),
  metadata: DatasetMetadataSchema
});
export type Dataset = z.infer<typeof DatasetSchema>;

export const MDMProjectSchema = z.object({
  id: z.number(),
  tenantId: z.string(),
  name: z.string(),
  description: z.string(),
  datasets: z.array(DatasetSchema)
});
export type MDMProject = z.infer<typeof MDMProjectSchema>;

export const MDMProjectResultSchema = z.object({
  id: z.number(),
  mdmProjectId: z.number(),
  mdmProject: MDMProjectSchema.optional(),
  runDate: z.coerce.date(),
  filePath: z.string(),
  status: z.string()
});
export type MDMProjectResult = z.infer<typeof MDMProjectResultSchema>;

export const ReviewStrategySchema = z.object({
  filters: z.array(
    z.object({
      fieldName: z.string(),
      op: z.string(),
      value: z
        .string()
        .or(z.number())
        .or(z.boolean())
        .or(z.array(z.string()).or(z.array(z.number())).or(z.array(z.boolean())))
    })
  ),
  validateFields: z.array(z.string()),
  sample: z
    .object({
      count: z.number()
    })
    .or(
      z.object({
        percentage: z.number()
      })
    )
});
export type ReviewStrategy = z.infer<typeof ReviewStrategySchema>;

export const MDMProjectReviewStrategySchema = z.object({
  id: z.number(),
  mdmProjectId: z.number(),
  mdmProject: MDMProjectSchema.optional(),
  name: z.string(),
  description: z.string().nullable(),
  strategy: ReviewStrategySchema
});
export type MDMProjectReviewStrategy = z.infer<typeof MDMProjectReviewStrategySchema>;

export const MDMProjectResultReviewStrategySchema = z.object({
  id: z.number(),
  mdmProjectId: z.number(),
  mdmProjectResultId: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  strategy: ReviewStrategySchema,
  itemsReviewedCount: z.number(),
  totalItemsCount: z.number()
});
export type MDMProjectResultReviewStrategy = z.infer<typeof MDMProjectResultReviewStrategySchema>;

export const MDMProjectResultReviewDetailSchema = z.object({
  fields: z.record(
    z
      .object({
        approved: z.literal(true)
      })
      .or(
        z.object({
          approved: z.literal(false),
          valueOverride: z.string().or(z.number()).or(z.boolean()),
          reason: z.string()
        })
      )
  )
});
export type MDMProjectResultReviewDetail = z.infer<typeof MDMProjectResultReviewDetailSchema>;

export const MDMProjectResultReviewSchema = z.object({
  id: z.number(),
  mdmProjectId: z.number(),
  mdmProjectResultId: z.number(),
  mdmProjectResultReviewStrategyId: z.number(),
  itemFireflaiId: z.string(),
  review: MDMProjectResultReviewDetailSchema.nullable()
});
export type MDMProjectResultReview = z.infer<typeof MDMProjectResultReviewSchema>;

export const MDMProjectResultReviewWithRecordSchema = MDMProjectResultReviewSchema.extend({
  record: z.object({
    scalarEntries: z.record(z.string().or(z.number()).or(z.boolean())),
    objectEntries: z.record(
      z.object({
        old: z.string().or(z.number()).or(z.boolean()),
        new: z.string().or(z.number()).or(z.boolean())
      })
    )
  })
});
export type MDMProjectResultReviewWithRecord = z.infer<typeof MDMProjectResultReviewWithRecordSchema>;
