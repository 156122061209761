import { MinusCircleIcon, BookmarkIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { SourceEnrichmentKind } from './types';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { SavedTemplatesPanel } from './SavedTemplatesPanel';
import { useState } from 'react';

export function EnrichmentConfigPanelFields() {
  const {
    enrichmentGroups,
    loadingSuggestedGroup,
    updateEnrichmentField,
    updateEnrichmentFieldKind,
    addEnrichmentField,
    removeEnrichmentField,
    addEnrichmentGroup,
    renameEnrichmentGroup,
    removeEnrichmentGroup,
    suggestEnrichmentGroup,
    savedTemplates
  } = useEnrichmentConfig();

  const [activeTab, setActiveTab] = useState<'editor' | 'templates'>('editor');

  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <h3 className="text-xl">Enrichment Fields</h3>

        {/* Simple tab buttons instead of complex navigation */}
        <div className="flex space-x-2">
          <button
            onClick={() => setActiveTab('editor')}
            className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors flex items-center
              ${activeTab === 'editor' ? 'bg-blue-100 text-blue-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
          >
            <PencilSquareIcon className="h-4 w-4 mr-1.5" />
            Editor
          </button>
          <button
            onClick={() => setActiveTab('templates')}
            className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors flex items-center
              ${
                activeTab === 'templates' ? 'bg-blue-100 text-blue-700' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
          >
            <BookmarkIcon className="h-4 w-4 mr-1.5" />
            Templates{' '}
            {savedTemplates.length > 0 && (
              <span className="ml-1.5 bg-blue-500 text-white text-xs font-semibold px-1.5 py-0.5 rounded-full">
                {savedTemplates.length}
              </span>
            )}
          </button>
        </div>
      </div>

      <div className="mt-4 pt-4 border-t border-gray-200">
        {activeTab === 'editor' ? (
          <div>
            <div className="flex justify-between items-center mb-4">
              <p className="text-sm text-gray-600">
                Add the fields you wish to enrich data for. Use groups to categorise the fields.
              </p>
              {savedTemplates.length > 0 && (
                <button
                  onClick={() => setActiveTab('templates')}
                  className="text-sm text-blue-600 hover:text-blue-800 flex items-center"
                >
                  <BookmarkIcon className="h-4 w-4 mr-1" />
                  Load a saved template
                </button>
              )}
            </div>
            <div className="my-2 flex flex-col gap-2">
              {enrichmentGroups.map((enrichmentGroup, enrichmentGroupIndex) => (
                <div key={enrichmentGroupIndex} className="border border-gray-200 rounded-md p-3 shadow-sm">
                  <div className="flex">
                    <input
                      className="w-full my-2 border-white px-0 rounded-md h-8 font-medium"
                      value={enrichmentGroup.groupName}
                      onChange={(event) => {
                        renameEnrichmentGroup(enrichmentGroupIndex, event.target.value);
                      }}
                    />
                    <button
                      className="h-8 w-8 p-1 text-gray-600 hover:text-red-500"
                      onClick={() => {
                        removeEnrichmentGroup(enrichmentGroupIndex);
                      }}
                      title="Remove group"
                    >
                      <MinusCircleIcon />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2">
                    {enrichmentGroup.fields.map((field, fieldIndex) => (
                      <div className="flex items-center" key={fieldIndex}>
                        <input
                          className="flex-grow shadow-sm border border-gray-300 rounded-md w-full text-sm h-8"
                          value={field.fieldName}
                          onChange={(event) => {
                            updateEnrichmentField(enrichmentGroupIndex, fieldIndex, event.target.value);
                          }}
                        />
                        <select
                          className="border-0 mx-0 text-xs w-28"
                          value={field.kind}
                          onChange={(event) => {
                            updateEnrichmentFieldKind(
                              enrichmentGroupIndex,
                              fieldIndex,
                              event.target.value as SourceEnrichmentKind
                            );
                          }}
                        >
                          <option className="mx-0" value={'extracted'}>
                            extracted
                          </option>
                          <option className="mx-0" value={'generated'}>
                            generated
                          </option>
                        </select>
                        <button
                          className="flex-shrink-0 h-8 w-8 p-1 text-gray-600 hover:text-red-500"
                          onClick={() => {
                            removeEnrichmentField(enrichmentGroupIndex, fieldIndex);
                          }}
                          title="Remove field"
                        >
                          <MinusCircleIcon />
                        </button>
                      </div>
                    ))}
                    <div className="flex">
                      <div className="flex-grow shadow-sm border border-gray-300 rounded-md">
                        <button
                          className="text-sm w-full text-center h-8 bg-gray-50 hover:bg-gray-100 transition-colors"
                          onClick={() => {
                            addEnrichmentField(enrichmentGroupIndex);
                          }}
                        >
                          Add Field
                        </button>
                      </div>
                      <div className="w-8"></div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex gap-x-2 mt-2">
                <button
                  className="py-2 px-4 shadow-sm border border-gray-300 rounded-md text-sm text-center h-10 bg-gray-50 hover:bg-gray-100 transition-colors"
                  onClick={() => {
                    addEnrichmentGroup();
                  }}
                >
                  Add Group
                </button>
                <button
                  className="py-2 px-4 shadow-sm border border-gray-300 rounded-md text-sm text-center h-10 bg-gray-50 hover:bg-gray-100 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={() => {
                    suggestEnrichmentGroup();
                  }}
                  disabled={loadingSuggestedGroup}
                >
                  {loadingSuggestedGroup ? 'Generating...' : 'Suggest Group'}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <SavedTemplatesPanel onClose={() => setActiveTab('editor')} />
        )}
      </div>
    </div>
  );
}
