import { useParams, useNavigate } from 'react-router-dom';
import { SectionHeader } from '../components/SectionHeader';
import { MDMProjectResultReviewStrategy, MDMProjectResultReviewWithRecord } from '../types';
import { useCallback, useEffect, useState } from 'react';
import { apiClient } from '../api';

// ...existing code...
export function MDMProjectResultDoReviewPage() {
  let { projectId, resultId, strategyId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    document.title = 'MDM| Fireflai';
  });

  const [strategy, setStrategy] = useState<MDMProjectResultReviewStrategy>();

  const [reviewRecord, setReviewRecord] = useState<MDMProjectResultReviewWithRecord>();

  // Track form state for each field with object values
  const [formState, setFormState] = useState<
    Record<
      string,
      {
        isCorrect?: boolean;
        correctValue?: string;
        comment?: string;
      }
    >
  >({});

  const getNextItem = useCallback(async () => {
    const item = await apiClient.getNextReviewRecord(parseInt(projectId!), parseInt(resultId!), parseInt(strategyId!));

    if (item === null) {
      return navigate(`/demo/mdmv2/${projectId}/result/${resultId}`);
    }

    setReviewRecord(item);
  }, [projectId, resultId, strategyId, navigate]);

  useEffect(() => {
    apiClient
      .getProjectResultReviewStrategy(parseInt(projectId!), parseInt(resultId!), parseInt(strategyId!))
      .then((strategy) => {
        setStrategy(strategy);
        getNextItem();
      });
  }, [strategyId, projectId, resultId, getNextItem]);

  // Handle radio button changes (is the new value correct?)
  const handleIsCorrectChange = (fieldName: string, isCorrect: boolean) => {
    setFormState((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        isCorrect,
        correctValue: isCorrect ? undefined : prev[fieldName]?.correctValue,
        comment: isCorrect ? undefined : prev[fieldName]?.comment
      }
    }));
  };

  // Handle text input changes (correctValue, comment)
  const handleFieldChange = (fieldName: string, key: 'correctValue' | 'comment', value: string) => {
    setFormState((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        [key]: value
      }
    }));
  };

  // Submit handler (stub)
  const handleSubmit = () => {
    apiClient
      .addReview(parseInt(projectId!), parseInt(resultId!), parseInt(strategyId!), reviewRecord!.id, {
        fields: Object.fromEntries(
          Object.entries(formState).map(([fieldName, value]) => {
            if (value.isCorrect === true) {
              return [fieldName, { approved: true }];
            } else {
              return [fieldName, { approved: false, valueOverride: value.correctValue, reason: value.comment }];
            }
          })
        )
      })
      .then(() => {
        setFormState({});
        getNextItem();
      });
  };

  if (!strategy || !reviewRecord) {
    return null;
  }

  return (
    <div className="h-full flex flex-col p-8">
      <SectionHeader title="Review records" subtitle={strategy.name} />

      <div className="mt-4 space-y-4">
        {/* Display scalar fields first */}
        {Object.entries(reviewRecord.record.scalarEntries).map(([fieldName, value]) => (
          <div key={fieldName}>
            <p className="font-semibold">{fieldName}:</p>
            <p className="text-sm ml-2">{String(value)}</p>
          </div>
        ))}

        {/* Display object fields (those that need review) afterward */}
        {Object.entries(reviewRecord.record.objectEntries).map(([fieldName, value]) => {
          const val = value as { old: string | number | boolean; new: string | number | boolean };
          return (
            <div key={fieldName} className="p-2 border rounded">
              <p className="font-semibold">{fieldName}:</p>
              <p className="text-sm ml-2">
                <strong>Old:</strong> {String(val.old)}
              </p>
              <p className="text-sm ml-2">
                <strong>New:</strong> {String(val.new)}
              </p>

              {/* Is correct? */}
              <div className="mt-2">
                <span className="text-sm font-medium">Is the new value correct?</span>
                <div className="flex items-center gap-4 ml-2 mt-1">
                  <label className="text-sm flex items-center gap-1">
                    <input
                      type="radio"
                      name={`isCorrect-${fieldName}`}
                      onChange={() => handleIsCorrectChange(fieldName, true)}
                      checked={formState[fieldName]?.isCorrect === true}
                    />
                    Yes
                  </label>
                  <label className="text-sm flex items-center gap-1">
                    <input
                      type="radio"
                      name={`isCorrect-${fieldName}`}
                      onChange={() => handleIsCorrectChange(fieldName, false)}
                      checked={formState[fieldName]?.isCorrect === false}
                    />
                    No
                  </label>
                </div>
              </div>

              {/* If not correct, show fields for correctValue and comment */}
              {formState[fieldName]?.isCorrect === false && (
                <div className="space-y-2 ml-2 mt-2">
                  <div>
                    <label className="block text-sm font-medium">Correct value:</label>
                    <input
                      type="text"
                      className="border w-full p-1 rounded"
                      value={formState[fieldName]?.correctValue ?? ''}
                      onChange={(e) => handleFieldChange(fieldName, 'correctValue', e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Comment:</label>
                    <textarea
                      className="border w-full p-1 rounded"
                      value={formState[fieldName]?.comment ?? ''}
                      onChange={(e) => handleFieldChange(fieldName, 'comment', e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <button
        className="bg-blue-500 text-white px-4 py-2 text-sm rounded hover:bg-blue-600 transition-colors mt-6 self-start"
        disabled={Object.entries(reviewRecord.record.objectEntries).some(([key]) => {
          const itemState = formState[key];
          return !itemState || itemState.isCorrect === undefined;
        })}
        onClick={handleSubmit}
      >
        Submit and continue
      </button>
    </div>
  );
}
