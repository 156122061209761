import type { BackoffStrategy } from './backoff-strategies';
import { repeat, retryFlag } from '../helpers';
import HttpRequester, { HttpVerb, QueryParams, Headers } from './http-requester';

export type FetchFunc = typeof fetch;

export type Options = {
  fetch: FetchFunc;
  backoffFunc: BackoffStrategy;
  maxAttempts: number;
  waitFunc?: (ms: number) => Promise<void>;
};

const waitMs = async (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export default class HttpClient implements HttpRequester {
  private token?: string;

  constructor(private readonly options: Options) {
    if (!this.options.waitFunc) {
      this.options.waitFunc = waitMs;
    }
  }

  setToken(token?: string): void {
    this.token = token;
  }

  async doRequest(
    verb: HttpVerb,
    url: string,
    params: QueryParams = {},
    headers: Headers = {},
    body?: object
  ): Promise<Response> {
    const serialisedParams = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    if (this.token) {
      headers['Authorization'] = `bearer ${this.token}`;
    }

    const urlWithParams = `${url}${serialisedParams.length > 0 ? `?${serialisedParams}` : ''}`;

    const request = async (attempt: number) => {
      const response = await this.options.fetch(urlWithParams, {
        method: verb,
        headers,
        body: JSON.stringify(body)
      });

      if (response.status >= 500 && response.status <= 599) {
        await this.options.waitFunc!(this.options.backoffFunc(attempt));
        return retryFlag;
      }

      return response;
    };

    try {
      return repeat(request, this.options.maxAttempts);
    } catch (err) {
      throw err;
    }
  }
}
