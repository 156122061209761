// import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from 'react';
import { Button, ButtonTypes, Input, LoadingList } from '../../components';
import {
  useGetInvitationsQuery,
  useSendInvitationMutation,
  CreateInvitation,
  useRevokeInvitationMutation
} from '../../store/invitations';
import { useGetRolesQuery } from '../../store/roles';
import { Dropdown } from '../../components/Dropdown';
import { Alert } from '../../components/Alert';
import { DateTime } from 'luxon';
import { standardiseError } from '../../utils/errors';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

export const Invitations = () => {
  const { isLoading, data, isError, error } = useGetInvitationsQuery();
  const { isLoading: isLoadingRoles, data: roles } = useGetRolesQuery();
  const [sendInvitation, { isLoading: isSendingInvitation, data: sentInvitation }] = useSendInvitationMutation();
  const [revokeInvitation, { isLoading: isRevokingInvitation }] = useRevokeInvitationMutation();
  const [newInvitationEmail, setNewInvitationEmail] = useState('');
  const [newInvitationRole, setNewInvitationRole] = useState<{ label: string; id: string }>({
    label: 'Select a role',
    id: ''
  });

  useEffect(() => {
    document.title = 'User Invitations | Fireflai';
  });

  useEffect(() => {
    if (roles && roles.length > 0) {
      setNewInvitationRole({
        label: roles[0].name,
        id: roles[0].id
      });
    }
  }, [roles]);

  useEffect(() => {
    if (isError && error) {
      toast.error(standardiseError(error));
    }
  }, [isError, error]);

  const handleRevokeInvitation = async (invitationId: string) => {
    try {
      await revokeInvitation(invitationId).unwrap();
      toast.success('Invitation revoked successfully');
    } catch (error) {
      toast.error('Failed to revoke invitation');
    }
  };

  const handleSendInvitation = async () => {
    try {
      const invitation: CreateInvitation = {
        email: newInvitationEmail,
        roles: [newInvitationRole?.id],
        sendEmail: true
      };

      await sendInvitation(invitation);
      setNewInvitationEmail('');
      toast.success(`Invitation sent to ${newInvitationEmail}`);
    } catch (error) {
      toast.error('Failed to send invitation');
    }
  };

  return (
    <>
      {isLoading && isLoadingRoles && <LoadingList />}

      {sentInvitation && (
        <Alert
          className="mb-8"
          variant="success"
          message={`Invitation successfully sent to ${sentInvitation.inviteeEmail}!`}
          autoDismiss
        />
      )}

      {isError && error && (
        <div className="mb-8">
          <Alert variant="error" autoDismiss message={standardiseError(error)} />
        </div>
      )}

      {!isLoading && !isLoadingRoles && data && roles && (
        <>
          <div className="">
            <h2 className="text-lg font-semibold mb-2 xl:mb-4">Invite a user</h2>
            <div className="flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-4 items-start xl:items-center">
              <div className="w-full xl:w-72">
                <Input
                  type="text"
                  fullWidth
                  value={newInvitationEmail}
                  label={''}
                  onChange={(e) => setNewInvitationEmail(e.target.value)}
                  id="invite"
                  placeholder="Email address"
                  icon={'EnvelopeIcon'}
                />
              </div>

              <div className="w-40">
                <Dropdown
                  items={roles.map((role) => ({
                    id: role.id,
                    label: role.name
                  }))}
                  value={newInvitationRole}
                  onChange={(e) => setNewInvitationRole(e)}
                />
              </div>

              <Button
                variant={ButtonTypes.Primary}
                onClick={handleSendInvitation}
                disabled={isSendingInvitation}
                text="Send Invitation"
              />
            </div>
          </div>

          <div className="mt-8 space-y-4">
            {data.data.map((invitation) => (
              <div key={invitation.id}>
                <div className="bg-white p-4 xl:p-8 rounded-md border border-gray-200 text-sm hover:border-gradient-right hover:border-opacity-30 transition-all">
                  <div className="flex flex-col xl:flex-row xl:items-center space-y-4 xl:space-y-0 justify-between">
                    <div className="flex flex-col xl:flex-row items-start xl:items-center space-y-2 xl:space-y-0 xl:space-x-4">
                      <p className="font-semibold">{invitation.inviteeEmail}</p>
                      <span className="text-gray-400 hidden xl:block">•</span>
                      <p className="text-gray-500 font-light">
                        {`Role: ${invitation.roles.map((roleId) => roles.find((role) => role.id === roleId)?.name)}`}
                      </p>
                      <span className="text-gray-400 hidden xl:block">•</span>
                      <p className="text-gray-500 font-light">
                        Expires {DateTime.fromISO(invitation.expiresAt).toFormat('LLL dd, yyyy')}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <Button
                        variant={ButtonTypes.Secondary}
                        onClick={() => handleRevokeInvitation(invitation.id)}
                        text="Revoke Invitation"
                        disabled={isRevokingInvitation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {data.data.length === 0 && (
              <div className="bg-white p-8  rounded-md border border-gray-200 text-sm flex items-center space-x-4">
                <QuestionMarkCircleIcon className="h-10 w-10 text-gray-200" />
                <p className="text-gray-700 font-light text-md">No invitations found. Try sending one!</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
