import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { HIGHBOURNE_HEATING_ENRICHMENT_GROUPS } from './EnrichmentGroupConfigs';
import {
  EnrichmentGroupState,
  Source,
  SourceEnrichmentKind,
  ImageSearchOptions,
  DocumentSearchOptions,
  SavedEnrichmentTemplateState,
  SavedEnrichmentTemplate
} from './types';
import { enrichmentClient } from './api';
import { DocumentSearchConfig } from './DocumentSearchConfig';
import { ImageSearchConfig } from './ImageSearchConfig';
import { EnrichmentConfigPanelFields } from './EnrichmentConfigPanelFields';
import clsx from 'clsx';

interface EnrichmentConfigContextProps {
  sources: Source[];
  sourceBlacklist: string[];
  sourceLimit: number;
  enrichmentGroups: EnrichmentGroupState;
  productDescription: string;
  verifyProduct: boolean;
  loadingSuggestedGroup: boolean;
  enrichAllBatchSize: number;
  imageSearchOptions: ImageSearchOptions;
  documentSearchOptions: DocumentSearchOptions;
  savedTemplates: SavedEnrichmentTemplateState;
  updateSource: (idx: number, source: Source) => void;
  removeSource: (idx: number) => void;
  addSource: () => void;
  updateBlacklistSource: (idx: number, value: string) => void;
  removeBlacklistSource: (idx: number) => void;
  addBlacklistSource: () => void;
  setSourceLimit: React.Dispatch<React.SetStateAction<number>>;
  updateEnrichmentField: (enrichmentGroupIndex: number, fieldIndex: number, value: string) => void;
  updateEnrichmentFieldKind: (enrichmentGroupIndex: number, fieldIndex: number, kind: SourceEnrichmentKind) => void;
  addEnrichmentField: (enrichmentGroupIndex: number) => void;
  removeEnrichmentField: (enrichmentGroupIndex: number, fieldIndex: number) => void;
  addEnrichmentGroup: () => void;
  renameEnrichmentGroup: (enrichmentGroupIndex: number, value: string) => void;
  removeEnrichmentGroup: (enrichmentGroupIndex: number) => void;
  updateProductDescription: (newDescription: string) => void;
  toggleVerifyProduct: () => void;
  suggestEnrichmentGroup: () => void;
  setEnrichAllBatchSize: (value: number) => void;
  updateImageSearchOptions: (options: ImageSearchOptions) => void;
  updateDocumentSearchOptions: (options: DocumentSearchOptions) => void;
  saveCurrentTemplate: (name: string, description: string) => void;
  updateTemplate: (templateId: string, updates: Partial<SavedEnrichmentTemplate>) => void;
  deleteTemplate: (templateId: string) => void;
  loadTemplate: (templateId: string) => void;
}

const EnrichmentConfigContext = createContext<EnrichmentConfigContextProps | undefined>(undefined);

// Separate configuration UI component
export function EnrichmentConfigPanel() {
  const {
    productDescription,
    verifyProduct,
    imageSearchOptions,
    documentSearchOptions,
    updateProductDescription,
    toggleVerifyProduct,
    updateImageSearchOptions,
    updateDocumentSearchOptions
  } = useEnrichmentConfig();

  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-lg font-medium text-gray-900">Product Description</h3>
        <div className="mt-4">
          <textarea
            value={productDescription}
            onChange={(e) => updateProductDescription(e.target.value)}
            className="w-full h-32 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter product description..."
          />
        </div>
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-900">Product Validation</h3>
        <p className="mt-2 text-sm text-gray-600">Verify product is as described prior to enrichment.</p>
        <div className="mt-4">
          <button
            onClick={toggleVerifyProduct}
            className={clsx(
              'relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
              verifyProduct ? 'bg-blue-600' : 'bg-gray-200'
            )}
            role="switch"
            aria-checked={verifyProduct}
          >
            <span className="sr-only">Enable product validation</span>
            <span
              aria-hidden="true"
              className={clsx(
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                verifyProduct ? 'translate-x-5' : 'translate-x-0'
              )}
            />
          </button>
        </div>
      </div>

      <EnrichmentConfigPanelFields />

      <div>
        <h3 className="text-lg font-medium text-gray-900">Image Search Configuration</h3>
        <div className="mt-4">
          <ImageSearchConfig value={imageSearchOptions} onChange={updateImageSearchOptions} />
        </div>
      </div>

      <div>
        <h3 className="text-lg font-medium text-gray-900">Document Search Configuration</h3>
        <div className="mt-4">
          <DocumentSearchConfig value={documentSearchOptions} onChange={updateDocumentSearchOptions} />
        </div>
      </div>
    </div>
  );
}

// Context provider component
export function EnrichmentConfigProvider({ children }: React.PropsWithChildren<{}>) {
  // TODO: Prefix localstorage items with enirchment demo
  const [productDescription, setProductDescription] = useState(() => {
    return localStorage.getItem('productDescription') || '';
  });
  const [verifyProduct, setVerifyProduct] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem('verifyProduct') || 'false');
  });
  const [sources, setSources] = useState<Source[]>(() => {
    return JSON.parse(localStorage.getItem('sources') || '[]');
  });
  const [sourceBlacklist, setSourceBlacklist] = useState<string[]>(() => {
    return JSON.parse(localStorage.getItem('sourceBlacklist') || '[]');
  });
  const [sourceLimit, setSourceLimit] = useState(() => {
    return JSON.parse(localStorage.getItem('sourceLimit') || '5');
  });
  const [enrichmentGroups, setEnrichmentGroups] = useState<EnrichmentGroupState>(() => {
    return JSON.parse(localStorage.getItem('enrichmentGroups') || JSON.stringify(HIGHBOURNE_HEATING_ENRICHMENT_GROUPS));
  });
  const [newGroupsAdded, setNewGroupsAdded] = useState(0);
  const [blacklistItemsAdded, setBlacklistItemsAdded] = useState(0);
  const [sourcesAdded, setSourcesAdded] = useState(0);
  const [loadingSuggestedGroup, setLoadingSuggestedGroup] = useState(false);
  const [enrichAllBatchSize, setEnrichAllBatchSize] = useState(() => {
    return parseInt(localStorage.getItem('enrichAllBatchSize') || '1', 10);
  });
  const [imageSearchOptions, setImageSearchOptions] = useState<ImageSearchOptions>(() => {
    return JSON.parse(localStorage.getItem('imageSearchOptions') || '{"count": 5, "size": "All", "aspect": "All"}');
  });
  const [documentSearchOptions, setDocumentSearchOptions] = useState<DocumentSearchOptions>(() => {
    const savedOptions = localStorage.getItem('documentSearchOptions');
    const defaultOptions = {
      count: 5,
      fileTypes: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']
    };
    return savedOptions ? JSON.parse(savedOptions) : defaultOptions;
  });
  const [savedTemplates, setSavedTemplates] = useState<SavedEnrichmentTemplateState>(() => {
    return JSON.parse(localStorage.getItem('savedTemplates') || '[]');
  });

  useEffect(() => {
    localStorage.setItem('productDescription', productDescription);
  }, [productDescription]);

  useEffect(() => {
    localStorage.setItem('verifyProduct', JSON.stringify(verifyProduct));
  }, [verifyProduct]);

  useEffect(() => {
    localStorage.setItem('sources', JSON.stringify(sources));
  }, [sources]);

  useEffect(() => {
    localStorage.setItem('sourceBlacklist', JSON.stringify(sourceBlacklist));
  }, [sourceBlacklist]);

  useEffect(() => {
    localStorage.setItem('sourceLimit', JSON.stringify(sourceLimit));
  }, [sourceLimit]);

  useEffect(() => {
    localStorage.setItem('enrichmentGroups', JSON.stringify(enrichmentGroups));
  }, [enrichmentGroups]);

  useEffect(() => {
    localStorage.setItem('enrichAllBatchSize', enrichAllBatchSize.toString());
  }, [enrichAllBatchSize]);

  useEffect(() => {
    localStorage.setItem('imageSearchOptions', JSON.stringify(imageSearchOptions));
  }, [imageSearchOptions]);

  useEffect(() => {
    localStorage.setItem('documentSearchOptions', JSON.stringify(documentSearchOptions));
  }, [documentSearchOptions]);

  useEffect(() => {
    localStorage.setItem('savedTemplates', JSON.stringify(savedTemplates));
  }, [savedTemplates]);

  const updateEnrichmentField = useCallback((enrichmentGroupIndex: number, fieldIndex: number, value: string) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = [...groupCopy.fields];
      groupCopy.fields[fieldIndex].fieldName = value;

      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const updateEnrichmentFieldKind = useCallback(
    (enrichmentGroupIndex: number, fieldIndex: number, kind: SourceEnrichmentKind) => {
      setEnrichmentGroups((prev) => {
        const prevCopy = [...prev];
        const groupCopy = { ...prev[enrichmentGroupIndex] };

        groupCopy.fields = [...groupCopy.fields];
        groupCopy.fields[fieldIndex].kind = kind;

        prevCopy[enrichmentGroupIndex] = groupCopy;

        return prevCopy;
      });
    },
    []
  );

  const addEnrichmentField = useCallback((enrichmentGroupIndex: number) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = [...groupCopy.fields, { fieldName: `New Field`, kind: SourceEnrichmentKind.Extracted }];
      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const removeEnrichmentField = useCallback((enrichmentGroupIndex: number, fieldIndex: number) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.fields = groupCopy.fields.filter((_, index) => index !== fieldIndex);

      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const addEnrichmentGroup = useCallback(() => {
    setEnrichmentGroups((prev) => [...prev, { groupName: `New Group ${newGroupsAdded + 1}`, fields: [] }]);
    setNewGroupsAdded((prev) => prev + 1);
  }, [newGroupsAdded]);

  const renameEnrichmentGroup = useCallback((enrichmentGroupIndex: number, value: string) => {
    setEnrichmentGroups((prev) => {
      const prevCopy = [...prev];
      const groupCopy = { ...prev[enrichmentGroupIndex] };

      groupCopy.groupName = value;
      prevCopy[enrichmentGroupIndex] = groupCopy;

      return prevCopy;
    });
  }, []);

  const removeEnrichmentGroup = useCallback((enrichmentGroupIndex: number) => {
    setEnrichmentGroups((prev) => {
      let prevCopy = [...prev];

      return prevCopy.filter((_, index) => index !== enrichmentGroupIndex);
    });
  }, []);

  const updateSource = (idx: number, source: Source) => {
    setSources((prev) => [...prev.slice(0, idx), source, ...prev.slice(idx + 1, prev.length)]);
  };
  const removeSource = (idx: number) => {
    setSources((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]);
  };
  const addSource = () => {
    setSources((prev) => [...prev, { name: `Source ${sourcesAdded + 1}`, aiGenerated: false }]);
    setSourcesAdded((prev) => prev + 1);
  };
  const updateBlacklistSource = (idx: number, value: string) => {
    setSourceBlacklist((prev) => [...prev.slice(0, idx), value, ...prev.slice(idx + 1, prev.length)]);
  };
  const removeBlacklistSource = (idx: number) => {
    setSourceBlacklist((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1, prev.length)]);
  };
  const addBlacklistSource = () => {
    setSourceBlacklist((prev) => [...prev, `Blacklist source ${blacklistItemsAdded + 1}`]);
    setBlacklistItemsAdded((prev) => prev + 1);
  };

  const toggleVerifyProduct = () => {
    setVerifyProduct((prev) => !prev);
  };

  const updateProductDescription = (newDescription: string) => {
    setProductDescription(newDescription);
  };

  const suggestEnrichmentGroup = async () => {
    setLoadingSuggestedGroup(true);

    const suggestedFields = await enrichmentClient.suggestProductEnrichmentGroup({
      productDescription,
      enrichmentGroups
    });

    if (suggestedFields.length > 0) {
      setEnrichmentGroups((prev) => [...prev, { groupName: `Suggested Fields`, fields: suggestedFields }]);
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    setLoadingSuggestedGroup(false);
  };

  const updateImageSearchOptions = useCallback((options: ImageSearchOptions) => {
    setImageSearchOptions(options);
  }, []);

  const updateDocumentSearchOptions = useCallback((options: DocumentSearchOptions) => {
    setDocumentSearchOptions(options);
  }, []);

  const saveCurrentTemplate = useCallback(
    (name: string, description: string) => {
      const newTemplate: SavedEnrichmentTemplate = {
        id: Date.now().toString(),
        name,
        description,
        groups: JSON.parse(JSON.stringify(enrichmentGroups)),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };
      setSavedTemplates((prev) => [...prev, newTemplate]);
    },
    [enrichmentGroups]
  );

  const updateTemplate = useCallback((templateId: string, updates: Partial<SavedEnrichmentTemplate>) => {
    setSavedTemplates((prev) =>
      prev.map((template) => (template.id === templateId ? { ...template, ...updates } : template))
    );
  }, []);

  const deleteTemplate = useCallback((templateId: string) => {
    setSavedTemplates((prev) => prev.filter((template) => template.id !== templateId));
  }, []);

  const loadTemplate = useCallback(
    (templateId: string) => {
      const template = savedTemplates.find((t) => t.id === templateId);
      if (template) {
        setEnrichmentGroups(JSON.parse(JSON.stringify(template.groups)));
      }
    },
    [savedTemplates]
  );

  return (
    <EnrichmentConfigContext.Provider
      value={{
        sources,
        sourceBlacklist,
        sourceLimit,
        enrichmentGroups,
        productDescription,
        verifyProduct,
        loadingSuggestedGroup,
        enrichAllBatchSize,
        imageSearchOptions,
        documentSearchOptions,
        savedTemplates,
        setEnrichAllBatchSize,
        setSourceLimit,
        updateSource,
        removeSource,
        addSource,
        updateBlacklistSource,
        removeBlacklistSource,
        addBlacklistSource,
        updateEnrichmentField,
        updateEnrichmentFieldKind,
        addEnrichmentField,
        removeEnrichmentField,
        addEnrichmentGroup,
        renameEnrichmentGroup,
        removeEnrichmentGroup,
        updateProductDescription,
        toggleVerifyProduct,
        suggestEnrichmentGroup,
        updateImageSearchOptions,
        updateDocumentSearchOptions,
        saveCurrentTemplate,
        updateTemplate,
        deleteTemplate,
        loadTemplate
      }}
    >
      {children}
    </EnrichmentConfigContext.Provider>
  );
}

export const useEnrichmentConfig = () => {
  const context = useContext(EnrichmentConfigContext);
  if (context === undefined) {
    throw new Error('useEnrichmentConfigContext must be used within an EnrichmentConfigProvider');
  }
  return context;
};
