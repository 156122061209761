import { Link, RouteObject } from 'react-router-dom';
import { EnrichmentConfigProvider, EnrichmentDemo, EnrichmentImportedDataProvider } from '../pages/Enrichment';
import { ProductEnrichmentProvider } from '../pages/Enrichment/Enrichment';

export const ENRICHMENT_ROUTES: RouteObject[] = [
  {
    path: '/enrichment',
    element: (
      <EnrichmentConfigProvider>
        <EnrichmentImportedDataProvider>
          <ProductEnrichmentProvider>
            <EnrichmentDemo />
          </ProductEnrichmentProvider>
        </EnrichmentImportedDataProvider>
      </EnrichmentConfigProvider>
    ),
    handle: {
      crumb: () => <Link to="/enrichment">Enrichment</Link>
    }
  }
];
