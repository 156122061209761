import { Link, RouteObject } from 'react-router-dom';
import { AssetLibrary } from '../pages';

export const ASSET_LIBRARY_ROUTES: RouteObject[] = [
  {
    path: '/asset-library',
    handle: { crumb: () => <Link to="/asset-library">Asset Library</Link> },
    element: <AssetLibrary />
  }
];
