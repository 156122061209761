import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { ColumnIdentifier, EnrichmentDataTableHeader, EnrichmentDataTableRow } from './types';
import { parseCsvFile, parseCsvString } from './csv';
import {
  getActiveFileName,
  setActiveFileName as setSharedActiveFileName,
  getAllStoredFiles,
  getActiveFileData
} from '../../services/SharedFileStorage';

type ImportedData = {
  headers: EnrichmentDataTableHeader[];
  data: EnrichmentDataTableRow[];
  loading: boolean;
  canReload: boolean;
  error?: string;
};

type ImportedDataState = {
  [fileName: string]: ImportedData;
};

interface EnrichmentImportedDataContextProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  importedData: ImportedDataState;
  reloadImportedData: (fileName: string) => void;
  removeImportedData: (fileName: string) => void;
  activeFileName: string | null;
  setActiveFileName: (fileName: string | null) => void;
  updateActiveHeaderKind: (headerId: string, kind: string | null) => void;
  getProductRowIdentifiers: (rowId: string) => ColumnIdentifier[];
  activeImportedData: ImportedData | null;
  identifierHeadersSelected: boolean;
}

function initialiseImportedData(): ImportedDataState {
  const jsonState = localStorage.getItem('importedData');
  if (!jsonState) {
    return {};
  }
  const result: ImportedDataState = {};

  const parsedState: ImportedDataState = JSON.parse(jsonState);
  Object.entries(parsedState).forEach(([fileName, importedData]) => {
    result[fileName] = {
      headers: importedData.headers,
      data: importedData.data,
      loading: false,
      canReload: false,
      error: importedData.error
    };
  });

  return result;
}

const EnrichmentImportedDataContext = createContext<EnrichmentImportedDataContextProps | undefined>(undefined);

export const EnrichmentImportedDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [importedData, setImportedData] = useState<ImportedDataState>(() => initialiseImportedData());

  // Use the shared file storage for activeFileName
  const [activeFileName, setLocalActiveFileName] = useState<string | null>(() => getActiveFileName());

  // Set both local and shared active file name
  const setActiveFileName = useCallback((fileName: string | null) => {
    setLocalActiveFileName(fileName);
    setSharedActiveFileName(fileName);
  }, []);

  // Check for files from shared storage on mount
  useEffect(() => {
    const sharedFiles = getAllStoredFiles();
    const sharedFilesList = Object.values(sharedFiles);

    // If we have files in shared storage and an active file is set, make sure it's loaded
    if (sharedFilesList.length > 0 && activeFileName) {
      const activeSharedFile = sharedFiles[activeFileName];
      if (activeSharedFile) {
        // Create File object from shared storage if possible
        if (!files.some((f) => f.name === activeFileName)) {
          // Get the file content stored in shared storage using the proper function
          const fileContent = getActiveFileData();

          if (fileContent && typeof fileContent === 'string') {
            // Parse the CSV string content
            parseCsvString(fileContent)
              .then(({ headers, data }) => {
                setImportedData((prev) => ({
                  ...prev,
                  [activeFileName]: {
                    headers,
                    data,
                    loading: false,
                    canReload: true,
                    error: undefined
                  }
                }));
              })
              .catch((err) => {
                const errorMessage = err instanceof Error ? err.message : 'Failed importing the file.';
                setImportedData((prev) => ({
                  ...prev,
                  [activeFileName]: {
                    headers: [],
                    data: [],
                    loading: false,
                    error: errorMessage,
                    canReload: true
                  }
                }));
              });
          } else {
            // Show a placeholder if we don't have content
            setImportedData((prev) => {
              if (!prev[activeFileName]) {
                return {
                  ...prev,
                  [activeFileName]: {
                    headers: [],
                    data: [],
                    loading: true,
                    canReload: true,
                    error: undefined
                  }
                };
              }
              return prev;
            });
          }
        }
      }
    }
  }, [activeFileName, files, setImportedData]);

  const importDataFromFile = useCallback(
    async (file: File) => {
      try {
        const { headers, data } = await parseCsvFile(file);
        setImportedData((prev) => ({ ...prev, [file.name]: { headers, data, loading: false, canReload: true } }));
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed importing the file.';
        setImportedData((prev) => ({
          ...prev,
          [file.name]: { headers: [], data: [], loading: false, error: errorMessage, canReload: true }
        }));
      }
    },
    [setImportedData]
  );

  const attemptFileImport = useCallback(
    (file: File) => {
      if (!importedData[file.name]) {
        // Initiate state for new file
        setImportedData((prev) => ({
          ...prev,
          [file.name]: { headers: [], data: [], loading: true, canReload: true }
        }));
        importDataFromFile(file);
      } else if (!importedData[file.name].canReload) {
        // File already imported, allow contents to be reloaded
        setImportedData((prev) => ({
          ...prev,
          [file.name]: { ...prev[file.name], canReload: true }
        }));
      }
    },
    [importedData, setImportedData, importDataFromFile]
  );

  const reloadImportedData = useCallback(
    (fileName: string) => {
      setImportedData((prev) => ({
        ...prev,
        [fileName]: { ...prev[fileName], loading: true, canReload: false }
      }));
      importDataFromFile(files.find((file) => file.name === fileName)!);
    },
    [setImportedData, files, importDataFromFile]
  );

  const removeImportedData = useCallback(
    (fileName: string) => {
      setImportedData((prev) => {
        const newState = { ...prev };
        delete newState[fileName];
        return newState;
      });
      setFiles((prev) => {
        const newFiles = prev.filter((file) => file.name !== fileName);
        return newFiles;
      });
      if (activeFileName === fileName) {
        setActiveFileName(null);
      }
    },
    [setImportedData, activeFileName, setActiveFileName, setFiles]
  );

  const updateActiveHeaderKind = useCallback(
    (headerId: string, kind: string | null) => {
      if (!activeFileName) {
        return;
      }
      setImportedData((prev) => {
        const newState = { ...prev };
        const headers = newState[activeFileName].headers.map((header) => {
          if (header.id === headerId) {
            return { ...header, kind };
          }
          return header;
        });
        newState[activeFileName] = { ...newState[activeFileName], headers };
        return newState;
      });
    },
    [activeFileName, setImportedData]
  );

  const getProductRowIdentifiers = useCallback(
    (rowId: string) => {
      if (!activeFileName) {
        throw new Error('Attempted to get product row identifiers without an active file');
      }

      const productRow = importedData[activeFileName].data.find((row) => row.id === rowId);

      if (!productRow) {
        throw new Error(`Attempted to get product row identifiers for non-existent row ${rowId}`);
      }

      const identifiers: ColumnIdentifier[] = [];
      importedData[activeFileName].headers.forEach((header, idx) => {
        if (header.kind === 'identifier' && productRow.cells.length > idx) {
          identifiers.push({ columnName: header.name, value: productRow.cells[idx].value });
        }
      });

      return identifiers;
    },
    [activeFileName, importedData]
  );

  useEffect(() => {
    localStorage.setItem('importedData', JSON.stringify(importedData));
  }, [importedData]);

  useEffect(() => {
    files.forEach((file) => {
      attemptFileImport(file);
    });
  }, [files, attemptFileImport]);

  const activeImportedData = activeFileName ? importedData[activeFileName] : null;

  const identifierHeadersSelected = activeImportedData
    ? activeImportedData.headers.filter((header) => header.kind === 'identifier').length > 0
    : false;

  return (
    <EnrichmentImportedDataContext.Provider
      value={{
        files,
        setFiles,
        importedData,
        reloadImportedData,
        removeImportedData,
        activeFileName,
        setActiveFileName,
        updateActiveHeaderKind,
        getProductRowIdentifiers,
        activeImportedData,
        identifierHeadersSelected
      }}
    >
      {children}
    </EnrichmentImportedDataContext.Provider>
  );
};

export const useImportedData = (): EnrichmentImportedDataContextProps => {
  const context = useContext(EnrichmentImportedDataContext);
  if (context === undefined) {
    throw new Error('useImportedData must be used within an ImportedDataProvider');
  }
  return context;
};
