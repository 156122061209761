import { DateTime } from 'luxon';
import { LoadingSpinner } from '../../components';
import { useGetActiveSubscriptionQuery } from '../../store/subscription';
import { standardiseError } from '../../utils/errors';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const Subscription = () => {
  const { data: subscription, isLoading: subscriptionIsLoading, error, isError } = useGetActiveSubscriptionQuery();

  useEffect(() => {
    document.title = 'Subscription | Fireflai';
  });

  useEffect(() => {
    if (isError && error) {
      toast.error(standardiseError(error));
    }
  }, [isError, error]);

  return (
    <div className="w-full h-full">
      {subscriptionIsLoading && (
        <div className="w-full h-full flex justify-center items-center" style={{ height: '450px' }}>
          <LoadingSpinner />
        </div>
      )}

      {!subscriptionIsLoading && subscription && (
        <div className="space-y-4">
          <div>
            <p className="text-md font-base mb-2">Subscription Name</p>
            <p className="text-sm font-light mb-2">{subscription.name}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Tier</p>
            <p className="text-sm font-light mb-2 capitalize">{subscription.tier}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Start Date</p>
            <p className="text-sm font-light mb-2">
              {DateTime.fromISO(subscription.startsOn).toFormat('dd LLL, yyyy')}
            </p>
          </div>
          <div>
            <p className="text-md font-base mb-2">End Date</p>
            <p className="text-sm font-light mb-2">{DateTime.fromISO(subscription.endsOn).toFormat('dd LLL, yyyy')}</p>
          </div>
          <div>
            <p className="text-md font-base mb-2">Available products</p>
            <ul className="text-sm font-light mb-2 capitalize">
              {subscription.products
                .filter((p) => p.enabled)
                .map((product) => (
                  <li key={product.productName}>{product.productName}</li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
