import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { prepHeaders } from './utils';
import { env } from '../env';

export interface SasToken {
  token: string;
  baseUrl: string;
  relativePath: string;
}

export const filesApi = createApi({
  reducerPath: 'files',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_ENDPOINT_APP}/files/vault`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Files],
  endpoints: (builder) => ({
    getVaultToken: builder.mutation<SasToken, void>({
      query: () => ({
        url: '/token',
        method: 'POST'
      })
    })
  })
});

export const { useGetVaultTokenMutation } = filesApi;
