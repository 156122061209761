import { Link, RouteObject } from 'react-router-dom';
import { MDMProjectListPage } from '../pages/MDM/pages/MDMProjectListPage';
import { MDMProjectPage } from '../pages/MDM/pages/MDMProjectPage';
import { MDMProjectResultReviewPage } from '../pages/MDM/pages/MDMProjectResultReviewPage';
import { MDMProjectResultDoReviewPage } from '../pages/MDM/pages/MDMProjectResultDoReviewPage';

export const MDM_ROUTES: RouteObject[] = [
  {
    path: '/mdm',
    children: [
      {
        index: true,
        element: <MDMProjectListPage />,
        handle: {
          crumb: () => {
            return <p>MDM</p>;
          }
        }
      },
      {
        path: ':projectId',
        handle: {
          crumb: () => <p>Projects</p>
        },
        children: [
          {
            index: true,
            element: <MDMProjectPage />
          },
          {
            path: 'strategy/:strategyId',
            element: null,
            handle: {
              crumb: () => {
                return <p>Strategy</p>;
              }
            }
          },
          {
            path: 'result/:resultId',
            handle: {
              crumb: () => {
                return <p>Result</p>;
              }
            },
            children: [
              {
                index: true,
                element: <MDMProjectResultReviewPage />
              },
              {
                path: 'strategy/:strategyId',
                element: <MDMProjectResultDoReviewPage />,
                handle: {
                  crumb: () => {
                    return <p>Review</p>;
                  }
                }
              }
            ]
          }
        ]
      }
    ],
    handle: {
      crumb: () => <Link to="/mdm">MDM V2</Link>
    }
  }
];
