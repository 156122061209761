import {
  CreateCleanQueryRequestBody,
  EnrichProductFieldsRequestBody,
  ImageSearchResponse,
  DocumentSearchResponse,
  ImageSearchResultMap,
  DocumentSearchResultMap,
  SearchWebRequestBody,
  SourceEnrichmentField,
  SourceEnrichmentSummaries,
  SourceEnrichmentValues,
  SuggestEnrichmentGroupOptions,
  SummariseEnrichmentResultsRequestBody,
  WebPageSearchResult,
  WebScrapeRequestBody,
  ImageSearchOptions,
  DocumentSearchOptions
} from '../../types';
import { RequestError } from '../errors';
import HttpRequester from './http-requester';

export default class HttpEnrichmentClient {
  private readonly endpoint: string;

  constructor(
    private readonly http: HttpRequester,
    readonly domain: string
  ) {
    this.endpoint = domain;
  }

  async searchImages(query: string, options: ImageSearchOptions = {}) {
    const endpoint = `${this.domain}/image-search`;

    const response = await this.http.doRequest(
      'POST',
      endpoint,
      undefined,
      { 'Content-Type': 'application/json' },
      {
        query,
        ...options
      }
    );

    if (response.status > 299 || response.status < 200) {
      throw new RequestError('GET', endpoint, response.statusText);
    }
    const result: ImageSearchResponse = await response.json();

    return result;
  }

  async webSearch(body: SearchWebRequestBody): Promise<WebPageSearchResult[]> {
    const endpoint = `${this.domain}/web-search`;
    try {
      const response = await this.http.doRequest(
        'POST',
        endpoint,
        undefined,
        { 'Content-Type': 'application/json' },
        body
      );

      if (response.status > 299 || response.status < 200) {
        throw new RequestError('GET', endpoint, response.statusText);
      }

      const result = await response.json();

      return result;
    } catch (err) {
      return [];
    }
  }

  async createCleanQuery(body: CreateCleanQueryRequestBody): Promise<string> {
    const endpoint = `${this.domain}/clean-query`;
    try {
      const response = await this.http.doRequest(
        'POST',
        endpoint,
        undefined,
        { 'Content-Type': 'application/json' },
        body
      );

      if (response.status > 299 || response.status < 200) {
        throw new RequestError('GET', endpoint, response.statusText);
      }

      const result = await response.text();

      return result;
    } catch (err) {
      throw err;
    }
  }

  async scrapeWebPageText(url: string): Promise<string> {
    const endpoint = `${this.domain}/scrape-web-page-text`;
    const body: WebScrapeRequestBody = { url };

    // Add retry logic with exponential backoff
    const maxRetries = 3;

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const response = await this.http.doRequest(
          'POST',
          endpoint,
          undefined,
          { 'Content-Type': 'application/json' },
          body
        );

        if (response.status === 500) {
          // If it's a 500 error, wait and retry
          const delay = Math.pow(2, attempt) * 1000; // exponential backoff
          await new Promise((resolve) => setTimeout(resolve, delay));
          continue;
        }

        if (response.status > 299 || response.status < 200) {
          throw new RequestError('POST', endpoint, response.statusText);
        }

        const result = await response.text();
        return result;
      } catch (err) {
        if (attempt < maxRetries - 1) {
          const delay = Math.pow(2, attempt) * 1000;
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }
    }

    // Return empty string instead of throwing to allow enrichment to continue
    // The error will be logged by the error handling system
    return '';
  }

  async enrichProductFields(body: EnrichProductFieldsRequestBody): Promise<SourceEnrichmentValues> {
    const endpoint = `${this.domain}/enrich-product-fields`;
    try {
      const response = await this.http.doRequest(
        'POST',
        endpoint,
        undefined,
        { 'Content-Type': 'application/json' },
        body
      );

      if (response.status > 299 || response.status < 200) {
        throw new RequestError('GET', endpoint, response.statusText);
      }

      const result = await response.json();

      return result;
    } catch (err) {
      return {};
    }
  }

  async summariseEnrichmentGroupResult(
    body: SummariseEnrichmentResultsRequestBody
  ): Promise<SourceEnrichmentSummaries> {
    const endpoint = `${this.domain}/summarise-enrichment-results`;
    try {
      const response = await this.http.doRequest(
        'POST',
        endpoint,
        undefined,
        { 'Content-Type': 'application/json' },
        body
      );

      if (response.status > 299 || response.status < 200) {
        throw new RequestError('GET', endpoint, response.statusText);
      }

      const result = await response.json();

      return result;
    } catch (err) {
      throw err;
    }
  }

  async imageDownload(imageResultMap: ImageSearchResultMap): Promise<Blob> {
    const endpoint = `${this.domain}/image-download`;
    const response = await this.http.doRequest(
      'POST',
      endpoint,
      undefined,
      { 'Content-Type': 'application/json' },
      { imageResultMap }
    );

    if (response.status > 299 || response.status < 200) {
      throw new RequestError('GET', endpoint, response.statusText);
    }

    // Convert response to blob (zip file)
    const zipBlob = await response.blob();

    return zipBlob;
  }

  async documentDownload(documentResultMap: DocumentSearchResultMap): Promise<Blob> {
    const endpoint = `${this.domain}/document-download`;
    const response = await this.http.doRequest(
      'POST',
      endpoint,
      undefined,
      {
        'Content-Type': 'application/json',
        Accept: 'application/zip'
      },
      { documentResultMap }
    );

    if (response.status > 299 || response.status < 200) {
      throw new RequestError('GET', endpoint, response.statusText);
    }

    // Convert response to blob (zip file)
    const zipBlob = await response.blob();

    return zipBlob;
  }

  async suggestProductEnrichmentGroup(options: SuggestEnrichmentGroupOptions): Promise<SourceEnrichmentField[]> {
    const endpoint = `${this.domain}/suggest-enrichment-group`;
    try {
      const response = await this.http.doRequest(
        'POST',
        endpoint,
        undefined,
        { 'Content-Type': 'application/json' },
        options
      );

      if (response.status > 299 || response.status < 200) {
        throw new RequestError('GET', endpoint, response.statusText);
      }

      const result: SourceEnrichmentField[] = await response.json();

      return result;
    } catch (err) {
      return [];
    }
  }

  async documentSearch(options: { query: string } & DocumentSearchOptions): Promise<DocumentSearchResponse> {
    const endpoint = `${this.domain}/document-search`;
    try {
      const response = await this.http.doRequest(
        'POST',
        endpoint,
        undefined,
        { 'Content-Type': 'application/json' },
        options
      );

      if (response.status > 299 || response.status < 200) {
        throw new RequestError('POST', endpoint, response.statusText);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      throw new RequestError('POST', endpoint, error instanceof Error ? error.message : 'Unknown error');
    }
  }
}
