import React from 'react';
import { DocumentSearchResult } from './types';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

interface DocumentSearchResultsProps {
  documents: DocumentSearchResult[];
  onRemoveDocument: (documentId: string) => void;
  onRegenerateDocumentType?: (documentType: string) => void;
}

export function DocumentSearchResults({
  documents,
  onRemoveDocument,
  onRegenerateDocumentType
}: DocumentSearchResultsProps) {
  if (!documents || documents.length === 0) {
    return null;
  }

  // Group documents by type
  const documentsByType = documents.reduce<Record<string, DocumentSearchResult[]>>((acc, doc) => {
    const type = doc.documentType || 'Other';
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(doc);
    return acc;
  }, {});

  return (
    <div className="mt-4">
      <h3 className="text-sm font-medium text-gray-900 mb-4">Document Search Results</h3>
      <div className="space-y-8">
        {Object.entries(documentsByType).map(([docType, docs]) => (
          <div key={docType} className="space-y-4">
            <div className="flex justify-between items-center">
              <h4 className="text-sm font-medium text-gray-700">{docType}</h4>
              {onRegenerateDocumentType && (
                <button
                  onClick={() => onRegenerateDocumentType(docType)}
                  className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                  Regenerate
                </button>
              )}
            </div>
            <div className="space-y-4 pl-4 border-l-2 border-gray-200">
              {docs.map((doc) => (
                <div key={doc.id} className="relative bg-white border border-gray-200 rounded-lg shadow-sm p-4">
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <h4 className="text-sm font-medium text-blue-600 hover:text-blue-800">
                        <a href={doc.url} target="_blank" rel="noopener noreferrer">
                          {doc.name}
                        </a>
                      </h4>
                      <p className="mt-1 text-sm text-gray-600">{doc.snippet}</p>
                      <div className="mt-2 flex flex-wrap gap-2">
                        {doc.tags.map((tag, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>
                      <div className="mt-2 text-xs text-gray-500 space-x-4">
                        {doc.datePublished && (
                          <span>Published: {new Date(doc.datePublished).toLocaleDateString()}</span>
                        )}
                        {doc.dateLastCrawled && (
                          <span>Last crawled: {new Date(doc.dateLastCrawled).toLocaleDateString()}</span>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => onRemoveDocument(doc.id)}
                      className="ml-4 text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">Remove document</span>
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
