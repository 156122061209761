import ReactModal from 'react-modal';
import { ModalHeader } from '../../components/Modal/ModalHeader';
import { SourceEnrichmentFieldResult, SourceEnrichmentKind } from './types';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon, ArrowUpTrayIcon, Cog6ToothIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { RowEnrichmentResultState, splitEnrichmentGroupResultFields } from './enrichmentResultsReducer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  result: RowEnrichmentResultState | null;
  onUpdateFieldValue: (fieldName: string, value: string, groupName: string) => void;
  onRegenerateField: (fieldName: string, groupName: string) => Promise<void>;
};

export function ViewSourceEnrichmentResultModal(props: Props) {
  // TODO: Access result from context
  const [lazyResult, setLazyResult] = useState<RowEnrichmentResultState | null>(props.result);

  // TODO handle loading state for individual result sections
  useEffect(() => {
    if (props.result !== undefined) {
      setLazyResult(props.result);
    }
  }, [props.result]);

  // Update local state when field values change
  const handleUpdateFieldValue = (fieldName: string, value: string, groupName: string) => {
    props.onUpdateFieldValue(fieldName, value, groupName);

    // Update local state to reflect the change immediately
    setLazyResult((prev) => {
      if (!prev || !prev.data) return prev;

      return {
        ...prev,
        data: {
          ...prev.data,
          groups: prev.data.groups.map((group) => {
            if (group.groupName !== groupName) return group;

            return {
              ...group,
              fields: group.fields.map((field) => {
                if (field.fieldName !== fieldName) return field;

                return {
                  ...field,
                  values: [
                    {
                      value,
                      sourceName: 'Manual Edit',
                      sourceUrl: window.location.href
                    },
                    ...field.values
                  ]
                };
              })
            };
          })
        }
      };
    });
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={() => {
        props.onClose();
      }}
      onAfterOpen={() => {}}
      overlayClassName="fixed inset-0 bg-black/75"
      className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 w-[1000px] max-w-full h-[700px] max-h-full border border-gray-400 bg-white overflow-auto rounded-xl outline-none"
      contentLabel="Create secret Modal"
      closeTimeoutMS={150}
    >
      <div className="absolute top-0 left-0 w-full h-full px-12 pt-12 pb-2 overflow-auto">
        <div className="flex flex-col justify-around min-h-full">
          <ModalHeader title={`${lazyResult?.data?.cleanQuery}`} isLoading={false} />
          <h4 className="flex justify-between items-center">
            <span className="text-sm text-gray-400">Enrichment Results</span>
            {lazyResult?.data?.processingTime && (
              <span className="text-xs text-gray-400">
                Completed in {Math.round(lazyResult.data?.processingTime * 100) / 100}s
              </span>
            )}
          </h4>

          {lazyResult?.error && <p className="mt-8 mb-4 text-red-600">{lazyResult.error}</p>}

          <p className="mt-8 mb-4 text-gray-600">{lazyResult?.data?.summary}</p>

          <div className="my-4 flex justify-around gap-12">
            <div className="flex flex-col justify-around items-center rounded-lg shadow-xl border border-gray-100 py-10 gap-2 flex-grow w-1">
              <h3 className="text-4xl">{lazyResult?.data?.stats?.enrichedPct || 0}%</h3>
              <h4 className="text-green-600">Enrichment Rate</h4>
            </div>
            <div className="flex flex-col justify-around items-center rounded-lg shadow-xl border border-gray-100 py-10 gap-2 flex-grow w-1">
              <h3 className="text-4xl">{lazyResult?.data?.stats?.enrichedCount || 0}</h3>
              <h4 className="text-blue-600">Enriched Fields</h4>
            </div>
            <div className="flex flex-col justify-around items-center rounded-lg shadow-xl border border-gray-100 py-10 gap-2 flex-grow w-1">
              <h3 className="text-4xl">{lazyResult?.data?.stats?.nullCount || 0}</h3>
              <h4 className="text-red-600">Null Fields</h4>
            </div>
          </div>

          <div className="my-4 flex gap-8 justify-center items-center flex-wrap">
            {lazyResult?.data?.images?.map((img, imgIdx) => (
              <div className="relative rounded-lg overflow-hidden basis-1/4">
                <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-end items-start">
                  <button
                    className="w-6 text-gray-600"
                    onClick={() => {
                      setLazyResult((prev) => {
                        if (!prev || !prev.data || !prev.data.images) {
                          return null;
                        }
                        const imagesCopy = [...prev.data.images];

                        return { ...prev, images: imagesCopy.filter((_, idx) => idx !== imgIdx) };
                      });
                    }}
                  >
                    <XMarkIcon />
                  </button>
                </div>
                <img alt={img.name} src={img.contentUrl} />
              </div>
            ))}
          </div>

          {lazyResult?.data?.groups?.map((group) => {
            const { nullFields, nonNullFields } = splitEnrichmentGroupResultFields(group);

            return (
              <div key={group.groupName} className="my-4">
                <h3 className="text-xl">{group.groupName}</h3>

                <div className="my-4">
                  <p className="text-gray-600">{group.summary}</p>
                </div>

                <div className="flex justify-center">
                  <div className="my-4 flex justify-center gap-8 w-2/3">
                    <div className="flex flex-col justify-around items-center rounded-lg shadow-xl border border-gray-100 py-2 gap-1 flex-grow w-1">
                      <h3 className="text-2xl">{group.stats?.enrichedPct}%</h3>
                      <h4 className="text-green-600 text-sm">Enrichment Rate</h4>
                    </div>
                    <div className="flex flex-col justify-around items-center rounded-lg shadow-xl border border-gray-100 py-2 gap-1 flex-grow w-1">
                      <h3 className="text-2xl">{group.stats?.enrichedCount}</h3>
                      <h4 className="text-blue-600 text-sm">Enriched Fields</h4>
                    </div>
                    <div className="flex flex-col justify-around items-center rounded-lg shadow-xl border border-gray-100 py-2 gap-1 flex-grow w-1">
                      <h3 className="text-2xl">{group.stats?.nullCount}</h3>
                      <h4 className="text-red-600 text-sm">Null Fields</h4>
                    </div>
                  </div>
                </div>

                <div className="my-6 w-full shadow-md border-2 border-gray-300 rounded-md overflow-x-auto">
                  <table className="w-full">
                    <tbody>
                      {nonNullFields.map((field, fieldIdx) => (
                        <FieldRow
                          key={field.fieldName}
                          field={field}
                          oddIndex={fieldIdx % 2 === 0}
                          groupName={group.groupName}
                          onUpdateValue={(value) => handleUpdateFieldValue(field.fieldName, value, group.groupName)}
                          onRegenerate={() => props.onRegenerateField(field.fieldName, group.groupName)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
                {nullFields.length > 0 && (
                  <p className="mt-4 text-gray-600">
                    Null Fields: {nullFields.map((field) => field.fieldName).join(', ')}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </ReactModal>
  );
}

type FieldRowProps = {
  field: SourceEnrichmentFieldResult;
  oddIndex: boolean;
  groupName: string;
  onUpdateValue: (value: string) => void;
  onRegenerate: () => Promise<void>;
};

function FieldRow(props: FieldRowProps) {
  const [valuesListOpen, setValuesListOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(props.field.values[0]?.value?.toString() || '');
  const [isRegenerating, setIsRegenerating] = useState(false);

  // Update edit value when field values change
  useEffect(() => {
    setEditValue(props.field.values[0]?.value?.toString() || '');
  }, [props.field.values]);

  const handleSave = () => {
    props.onUpdateValue(editValue);
    setIsEditing(false);
  };

  const handleRegenerate = async () => {
    setIsRegenerating(true);
    try {
      await props.onRegenerate();
    } finally {
      setIsRegenerating(false);
    }
  };

  // Get the most recent non-null value
  const currentValue = props.field.values.find((v) => v.value != null)?.value;

  return (
    <tr key={props.field.fieldName}>
      <td className={clsx('pl-4 py-2', props.oddIndex ? 'bg-gray-100' : 'bg-gray-50')}>
        <div className="w-5">
          {props.field.kind === SourceEnrichmentKind.Extracted ? <ArrowUpTrayIcon /> : <Cog6ToothIcon />}
        </div>
      </td>
      <td className={clsx('px-1 py-2', props.oddIndex ? 'bg-gray-100' : 'bg-gray-50')}>{props.field.fieldName}</td>
      <td className={clsx('px-4 py-2', props.oddIndex ? 'bg-gray-100' : 'bg-gray-50')}>
        <div className="flex flex-col">
          {isEditing ? (
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                className="flex-grow px-2 py-1 border border-gray-300 rounded"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSave();
                  } else if (e.key === 'Escape') {
                    setIsEditing(false);
                    setEditValue(currentValue?.toString() || '');
                  }
                }}
              />
              <button onClick={handleSave} className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600">
                Save
              </button>
              <button
                onClick={() => {
                  setIsEditing(false);
                  setEditValue(currentValue?.toString() || '');
                }}
                className="px-2 py-1 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <p>{currentValue}</p>
              <button
                onClick={() => setIsEditing(true)}
                className="p-1 text-gray-500 hover:text-gray-700"
                title="Edit value"
              >
                <PencilIcon className="w-4 h-4" />
              </button>
              <button
                onClick={handleRegenerate}
                disabled={isRegenerating}
                className={clsx('p-1 text-gray-500 hover:text-gray-700', isRegenerating && 'animate-spin')}
                title="Regenerate value"
              >
                <ArrowPathIcon className="w-4 h-4" />
              </button>
            </div>
          )}
          <div
            className={clsx(
              'flex flex-col transition-[max-height] ease-in-out duration-300 overflow-hidden',
              valuesListOpen ? 'max-h-96' : 'max-h-0'
            )}
          >
            <div className="px-2 py-2 border border-gray-600 bg-white rounded-md flex flex-col gap-y-1 my-4 max-h-64 overflow-y-auto">
              {props.field.values.map((fieldValue, idx) => (
                <div
                  key={`${fieldValue.sourceUrl}-${idx}`}
                  className={clsx(
                    'mx-2 flex gap-x-4 text-sm items-center justify-between pb-2 pt-2',
                    idx !== props.field.values.length - 1 ? 'border-b' : ''
                  )}
                >
                  <span className="text-gray-700">{fieldValue.value}</span>

                  <a
                    href={fieldValue.sourceUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm text-gray-400"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {fieldValue.sourceName}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </td>
      <td className={clsx('px-2 py-2', props.oddIndex ? 'bg-gray-100' : 'bg-gray-50')}>
        <button className="w-5" onClick={() => setValuesListOpen((prev) => !prev)}>
          <ChevronDownIcon
            className={clsx('transition-transform duration-300', valuesListOpen ? 'rotate-180' : 'rotate-0')}
          />
        </button>
      </td>
    </tr>
  );
}
