import { SectionHeader } from './SectionHeader';
import { EnrichmentWizard } from './EnrichmentWizard';

export function EnrichmentDemo() {
  return (
    <div className="h-full flex flex-col p-8">
      <SectionHeader title="Product Enrichment" subtitle="Configure your required enrichment set up below." />
      <div className="flex-1 -mx-8 mt-8">
        <EnrichmentWizard />
      </div>
    </div>
  );
}
