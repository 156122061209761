import { ChangeEvent, useState } from 'react';
import { ArrowPathIcon, XMarkIcon, DocumentIcon } from '@heroicons/react/24/outline';

type Props = {
  onFileAdd: (newFiles: File[]) => void;
  accept?: string;
  isUploading?: boolean;
  onCancel?: () => void;
};

export function FileUpload(props: Props) {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  function handleFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    handleFiles(event.target.files);
  }

  function handleFiles(fileList: FileList | null) {
    if (fileList) {
      const newFiles = Array.from(fileList);
      setFiles((prev) => [...prev, ...newFiles]);
      props.onFileAdd(newFiles);
    }
  }

  function handleDragOver(event: React.DragEvent) {
    event.preventDefault();
    setIsDragging(true);
  }

  function handleDragLeave(event: React.DragEvent) {
    event.preventDefault();
    setIsDragging(false);
  }

  function handleDrop(event: React.DragEvent) {
    event.preventDefault();
    setIsDragging(false);
    handleFiles(event.dataTransfer.files);
  }

  function removeFile(index: number) {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  }

  function formatFileSize(bytes: number): string {
    if (bytes < 1024) return bytes + ' B';
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
    return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  }

  return (
    <div className="flex flex-col w-full space-y-4">
      <div
        className={`
          relative flex flex-col items-center justify-center w-full p-8 
          border-2 border-dashed rounded-lg transition-colors duration-200
          ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}
          ${props.isUploading ? 'cursor-not-allowed opacity-75' : 'cursor-pointer'}
        `}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="text-center">
          <span className="flex-shrink-0 bg-gray-100 border border-gray-300 rounded-md py-2 px-4 mb-3 text-sm uppercase inline-block">
            Choose Files
          </span>
          <p className="text-sm text-gray-500">or drag and drop files here</p>
        </div>
        <input
          type="file"
          multiple
          accept={props.accept}
          className="absolute left-0 top-0 h-full w-full opacity-0 cursor-pointer focus:outline-none"
          onChange={handleFileInputChange}
          disabled={props.isUploading}
        />
      </div>

      {files.length > 0 && (
        <div className="bg-white border border-gray-200 rounded-lg divide-y divide-gray-200">
          <div className="px-4 py-3 flex justify-between items-center">
            <h3 className="text-sm font-medium text-gray-900">
              {files.length} {files.length === 1 ? 'file' : 'files'} selected
            </h3>
            {props.onCancel && (
              <button onClick={props.onCancel} className="text-sm text-gray-500 hover:text-gray-700">
                Cancel
              </button>
            )}
          </div>
          <div className="divide-y divide-gray-200 max-h-[300px] overflow-y-auto">
            {files.map((file, index) => (
              <div key={index} className="px-4 py-3 flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <DocumentIcon className="h-5 w-5 text-gray-400" />
                  <div>
                    <p className="text-sm font-medium text-gray-900 truncate max-w-[200px]">{file.name}</p>
                    <p className="text-xs text-gray-500">{formatFileSize(file.size)}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  {props.isUploading && <ArrowPathIcon className="h-4 w-4 text-blue-500 animate-spin" />}
                  <button onClick={() => removeFile(index)} className="text-gray-400 hover:text-gray-500">
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
