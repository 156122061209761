import React, { useState, useMemo } from 'react';
import { MagnifyingGlassIcon, ArrowPathIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useImportedData } from './ImportedData';
import { useProductEnrichment } from './Enrichment';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { EnrichmentActionToolbar } from './EnrichmentActionToolbar';
import { ColumnIdentifier, ImageSearchResult, DocumentSearchResult } from './types';
import { DocumentSearchResults } from './DocumentSearchResults';
import clsx from 'clsx';
import { toast } from 'react-toastify';

function hasImages(data: any): data is { images: ImageSearchResult[] } {
  return data && Array.isArray(data.images);
}

function hasDocuments(data: any): data is { documents: DocumentSearchResult[] } {
  return data && Array.isArray(data.documents);
}

export function EnrichmentReviewStep() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [isAddingImage, setIsAddingImage] = useState(false);
  const [newImageUrl, setNewImageUrl] = useState('');
  const [addImageError, setAddImageError] = useState<string | null>(null);
  const [previewImage, setPreviewImage] = useState<ImageSearchResult | null>(null);

  const { activeImportedData, activeFileName, getProductRowIdentifiers } = useImportedData();
  const { enrichmentGroups, imageSearchOptions, documentSearchOptions } = useEnrichmentConfig();
  const {
    enrichmentResults,
    updateFieldValue,
    enrichProductRow,
    removeImage,
    removeDocument,
    regenerateImage,
    regenerateAllImages,
    regenerateDocuments
  } = useProductEnrichment();

  const activeFileResults = activeFileName ? enrichmentResults[activeFileName] : undefined;

  const rows = useMemo(() => {
    if (!activeImportedData || !activeFileResults) return [];

    return activeImportedData.data
      .filter((row) => {
        const matchesSearch =
          searchTerm === '' ||
          Object.values(getProductRowIdentifiers(row.id)).some((value) =>
            value.value.toLowerCase().includes(searchTerm.toLowerCase())
          );
        return matchesSearch;
      })
      .map((row) => ({
        id: row.id,
        identifiers: getProductRowIdentifiers(row.id),
        result: activeFileResults[row.id],
        isEnriched: !!activeFileResults[row.id]?.data,
        isLoading: activeFileResults[row.id]?.loading || false
      }));
  }, [activeImportedData, activeFileResults, searchTerm, getProductRowIdentifiers]);

  const selectedRow = selectedRowId ? rows.find((row) => row.id === selectedRowId) : null;

  const formatIdentifiers = (identifiers: ColumnIdentifier[]) => {
    return identifiers.map((id) => id.value).join(' - ');
  };

  const handleAddImage = async () => {
    if (!activeFileName || !selectedRow) return;
    if (!newImageUrl.trim()) {
      setAddImageError('Please enter an image URL');
      return;
    }

    try {
      // Test if image loads
      const img = new Image();
      img.onload = () => {
        const currentImages = selectedRow.result.data?.images || [];
        regenerateImage(activeFileName, selectedRow.id, currentImages.length, {
          enrichmentGroups,
          identifiers: selectedRow.identifiers,
          sourceBlacklist: [],
          sourceLimit: 3,
          sources: [],
          imageSearchOptions
        });

        setNewImageUrl('');
        setIsAddingImage(false);
        setAddImageError(null);
        toast.success('Image added successfully');
      };

      img.onerror = () => {
        setAddImageError('Failed to load image. Please check the URL and try again.');
        toast.error('Failed to load image. Please check the URL and try again.');
      };

      img.src = newImageUrl;
    } catch (error) {
      setAddImageError('Invalid image URL');
      toast.error('Invalid image URL');
    }
  };

  const handleEnrichProduct = async (rowId: string) => {
    if (!activeFileName) return;
    try {
      const identifiers = getProductRowIdentifiers(rowId);
      await enrichProductRow(activeFileName, rowId, {
        enrichmentGroups,
        identifiers,
        sourceBlacklist: [],
        sourceLimit: 3,
        sources: [],
        imageSearchOptions,
        documentSearchOptions
      });
    } catch (error) {
      toast.error('Failed to enrich product. Please try again.');
      throw error;
    }
  };

  const handleRegenerateDocuments = async () => {
    if (!activeFileName || !selectedRowId) return;
    try {
      await regenerateDocuments(activeFileName, selectedRowId, {
        enrichmentGroups,
        identifiers: getProductRowIdentifiers(selectedRowId),
        sourceBlacklist: [],
        sourceLimit: 3,
        sources: [],
        documentSearchOptions
      });
    } catch (error) {
      toast.error('Failed to regenerate documents. Please try again.');
      throw error;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <EnrichmentActionToolbar />

      <div className="flex flex-1 overflow-hidden">
        {/* Left Panel - Product List */}
        <div className="w-1/3 border-r border-gray-200 flex flex-col bg-white overflow-hidden">
          <div className="flex-none p-4 border-b border-gray-200">
            <div className="relative">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="mt-2 text-sm text-gray-500">{rows.length} products found</div>
          </div>

          <div className="overflow-auto flex-1">
            <div className="divide-y divide-gray-200">
              {rows.map((row) => (
                <div
                  key={row.id}
                  onClick={() => row.isEnriched && setSelectedRowId(row.id)}
                  className={clsx(
                    'w-full text-left p-4 relative',
                    selectedRowId === row.id ? 'bg-blue-50' : 'hover:bg-gray-50',
                    !row.isEnriched && 'opacity-50',
                    row.isEnriched && 'cursor-pointer'
                  )}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex-1">
                      <div className="font-medium">{formatIdentifiers(row.identifiers)}</div>
                      {row.result?.error && <div className="text-sm text-red-600 mt-1">{row.result.error}</div>}
                    </div>
                    {!row.isEnriched && !row.isLoading && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (activeFileName) {
                            handleEnrichProduct(row.id);
                            toast.info('Starting enrichment process...');
                          }
                        }}
                        className="px-3 py-1 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Enrich
                      </button>
                    )}
                  </div>
                  {row.isLoading && (
                    <div className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center">
                      <div className="bg-white rounded-lg px-3 py-1 text-sm font-medium text-gray-600 shadow-sm">
                        Generating...
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Panel - Enrichment Details */}
        <div className="flex-1 flex flex-col bg-white overflow-hidden">
          {selectedRow ? (
            <>
              <div className="flex-none px-6 py-4 border-b border-gray-200">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium">Product Details</h3>
                  <button
                    onClick={() => {
                      if (activeFileName && selectedRow) {
                        handleEnrichProduct(selectedRow.id);
                      }
                    }}
                    disabled={selectedRow.isLoading}
                    className={clsx(
                      'flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors',
                      selectedRow.isLoading
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50'
                    )}
                  >
                    <ArrowPathIcon className={clsx('w-4 h-4 mr-2', selectedRow.isLoading && 'animate-spin')} />
                    {selectedRow.isLoading ? 'Regenerating...' : 'Re-enrich All'}
                  </button>
                </div>
              </div>

              <div className="overflow-auto flex-1">
                <div className="p-6">
                  <div className="space-y-8">
                    {/* Product Images */}
                    {selectedRow?.result?.data &&
                      hasImages(selectedRow.result.data) &&
                      selectedRow.result.data.images.length > 0 && (
                        <div className="space-y-4">
                          <div className="flex justify-between items-center">
                            <h4 className="text-base font-medium">Product Images</h4>
                            <div className="flex gap-2">
                              <button
                                onClick={() => {
                                  if (activeFileName && selectedRow) {
                                    const currentImages = selectedRow.result.data?.images || [];
                                    regenerateImage(activeFileName, selectedRow.id, currentImages.length, {
                                      enrichmentGroups,
                                      identifiers: selectedRow.identifiers,
                                      sourceBlacklist: [],
                                      sourceLimit: 3,
                                      sources: [],
                                      imageSearchOptions
                                    });
                                  }
                                }}
                                className="px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md flex items-center gap-1"
                              >
                                <PlusIcon className="w-4 h-4" />
                                Generate New Image
                              </button>
                              <button
                                onClick={() => {
                                  if (activeFileName && selectedRow) {
                                    regenerateAllImages(activeFileName, selectedRow.id, {
                                      enrichmentGroups,
                                      identifiers: selectedRow.identifiers,
                                      sourceBlacklist: [],
                                      sourceLimit: 3,
                                      sources: [],
                                      imageSearchOptions
                                    });
                                  }
                                }}
                                className="px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md"
                              >
                                Regenerate All Images
                              </button>
                            </div>
                          </div>
                          <div className="grid grid-cols-6 gap-3">
                            {selectedRow.result.data.images.map((image: ImageSearchResult, index: number) => (
                              <div key={image.contentUrl} className="space-y-1">
                                <button
                                  onClick={() => setPreviewImage(image)}
                                  className="group relative aspect-square w-full overflow-hidden rounded-lg border border-gray-200 hover:border-blue-500 block"
                                >
                                  <img
                                    src={image.thumbnailUrl}
                                    alt={image.name}
                                    className="h-full w-full object-cover object-center group-hover:opacity-90"
                                  />
                                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity">
                                    <span className="text-white text-xs text-center px-2">Click to preview</span>
                                  </div>
                                </button>
                                <div className="flex gap-1 justify-center">
                                  <button
                                    onClick={() => {
                                      if (activeFileName && selectedRow) {
                                        removeImage(activeFileName, selectedRow.id, index);
                                      }
                                    }}
                                    className="p-1 text-red-600 hover:text-red-700 hover:bg-red-50 rounded"
                                    title="Remove image"
                                  >
                                    <TrashIcon className="w-3.5 h-3.5" />
                                  </button>
                                  <button
                                    onClick={() => {
                                      if (activeFileName && selectedRow) {
                                        regenerateImage(activeFileName, selectedRow.id, index, {
                                          enrichmentGroups,
                                          identifiers: selectedRow.identifiers,
                                          sourceBlacklist: [],
                                          sourceLimit: 3,
                                          sources: [],
                                          imageSearchOptions
                                        });
                                      }
                                    }}
                                    className="p-1 text-gray-600 hover:text-gray-700 hover:bg-gray-50 rounded"
                                    title="Regenerate this image"
                                  >
                                    <ArrowPathIcon className="w-3.5 h-3.5" />
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                    {/* Product Documents */}
                    {selectedRow?.result?.data && hasDocuments(selectedRow.result.data) && (
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <h4 className="text-base font-medium">Related Documents</h4>
                          <button
                            onClick={() => {
                              if (activeFileName && selectedRow) {
                                handleRegenerateDocuments();
                              }
                            }}
                            className="px-3 py-1.5 text-sm font-medium text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md flex items-center gap-1"
                          >
                            <ArrowPathIcon className="w-4 h-4" />
                            Refresh Documents
                          </button>
                        </div>
                        <DocumentSearchResults
                          documents={selectedRow.result.data.documents}
                          onRemoveDocument={(documentId) => {
                            if (activeFileName) {
                              removeDocument(activeFileName, selectedRow.id, documentId);
                            }
                          }}
                          onRegenerateDocumentType={(docType) => {
                            if (activeFileName && documentSearchOptions) {
                              // Create a new options object with only the selected document type
                              const typeSpecificOptions = {
                                ...documentSearchOptions,
                                documentTypes: [docType]
                              };
                              regenerateDocuments(activeFileName, selectedRow.id, {
                                enrichmentGroups,
                                identifiers: selectedRow.identifiers,
                                sourceBlacklist: [],
                                sourceLimit: 3,
                                sources: [],
                                documentSearchOptions: typeSpecificOptions
                              });
                            }
                          }}
                        />
                      </div>
                    )}

                    {/* Enrichment Groups */}
                    <div className="space-y-8">
                      {enrichmentGroups.map((group) => (
                        <div key={group.groupName} className="space-y-4">
                          <h4 className="text-base font-medium">{group.groupName}</h4>
                          <div className="space-y-6">
                            {group.fields.map((field) => {
                              const fieldValue = selectedRow.result.data?.groups
                                .find((g) => g.groupName === group.groupName)
                                ?.fields.find((f) => f.fieldName === field.fieldName)?.values[0];

                              return (
                                <EnrichmentField
                                  key={field.fieldName}
                                  field={{
                                    name: field.fieldName,
                                    value: fieldValue?.value != null ? String(fieldValue.value) : '',
                                    source: fieldValue?.sourceName,
                                    sourceUrl: fieldValue?.sourceUrl
                                  }}
                                  onUpdateValue={(value) => {
                                    if (activeFileName) {
                                      updateFieldValue(
                                        activeFileName,
                                        selectedRow.id,
                                        group.groupName,
                                        field.fieldName,
                                        value
                                      );
                                    }
                                  }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex-1 flex items-center justify-center text-gray-500">
              Select a product to view enrichment details
            </div>
          )}
        </div>
      </div>

      {/* Image Preview Modal */}
      {previewImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative max-w-[90vw] max-h-[90vh]">
            <button
              onClick={() => setPreviewImage(null)}
              className="absolute -top-4 -right-4 bg-white rounded-full p-1 shadow-lg hover:bg-gray-100"
            >
              <span className="text-xl font-medium px-2">×</span>
            </button>
            <img
              src={previewImage.contentUrl}
              alt={previewImage.name}
              className="max-w-full max-h-[90vh] object-contain rounded-lg"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-sm p-2 rounded-b-lg">
              <div className="flex justify-between items-center">
                <span>
                  {previewImage.width} × {previewImage.height}
                </span>
                <a
                  href={previewImage.hostPageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-300 hover:text-blue-200"
                >
                  View source
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add Image Modal */}
      {isAddingImage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Add Image</h3>
              <button
                onClick={() => {
                  setIsAddingImage(false);
                  setNewImageUrl('');
                  setAddImageError(null);
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <span className="sr-only">Close</span>×
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label htmlFor="imageUrl" className="block text-sm font-medium text-gray-700 mb-1">
                  Image URL
                </label>
                <input
                  type="url"
                  id="imageUrl"
                  value={newImageUrl}
                  onChange={(e) => setNewImageUrl(e.target.value)}
                  placeholder="https://example.com/image.jpg"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                {addImageError && <p className="mt-1 text-sm text-red-600">{addImageError}</p>}
              </div>

              <div className="flex justify-end gap-2">
                <button
                  onClick={() => {
                    setIsAddingImage(false);
                    setNewImageUrl('');
                    setAddImageError(null);
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddImage}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  Add Image
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

type EnrichmentFieldProps = {
  field: {
    name: string;
    value: string;
    source?: string;
    sourceUrl?: string;
  };
  onUpdateValue: (value: string) => void;
};

function EnrichmentField({ field, onUpdateValue }: EnrichmentFieldProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(field.value);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  // Move cursor to end when editing starts
  React.useEffect(() => {
    if (isEditing && textareaRef.current) {
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, [isEditing]);

  return (
    <div className="flex items-start gap-4">
      <div className="flex-1">
        <div className="flex items-center justify-between mb-1">
          <label className="block text-sm font-medium text-gray-700">{field.name}</label>
          {field.source && (
            <div className="text-xs text-gray-500 flex items-center">
              <span className="mr-1">Source:</span>
              {field.sourceUrl ? (
                <a
                  href={field.sourceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-blue-600 hover:text-blue-800 hover:underline"
                  title={`Visit source: ${field.sourceUrl}`}
                >
                  {field.source}
                </a>
              ) : (
                <span className="font-medium text-gray-600">{field.source}</span>
              )}
            </div>
          )}
        </div>
        {isEditing ? (
          <textarea
            ref={textareaRef}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onBlur={() => {
              setIsEditing(false);
              if (editValue !== field.value) {
                onUpdateValue(editValue);
              }
            }}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            rows={3}
            autoFocus
          />
        ) : (
          <div
            onClick={() => {
              setIsEditing(true);
              setEditValue(field.value);
            }}
            className="p-2 border border-gray-300 rounded-md min-h-[4rem] cursor-text hover:border-gray-400"
          >
            {field.value || <span className="text-gray-400">Click to edit...</span>}
          </div>
        )}
      </div>
    </div>
  );
}
