import {
  ArrowDownIcon,
  ChevronDoubleRightIcon,
  PlayIcon,
  StopIcon,
  XMarkIcon,
  DocumentIcon,
  PhotoIcon
} from '@heroicons/react/24/outline';
import { ProductIdentifiersRow, useProductEnrichment } from './Enrichment';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { useImportedData } from './ImportedData';
import clsx from 'clsx';
import { Tooltip } from '../../components/Tooltip';

export function EnrichmentActionToolbar() {
  const {
    enrichmentGroups,
    sources,
    sourceBlacklist,
    sourceLimit,
    enrichAllBatchSize,
    setEnrichAllBatchSize,
    imageSearchOptions,
    documentSearchOptions
  } = useEnrichmentConfig();

  const { activeImportedData, activeFileName, getProductRowIdentifiers, identifierHeadersSelected } = useImportedData();

  const {
    enrichmentResults,
    enrichAllLoading,
    downloadLoading,
    enrichAllStopped,
    skipEnriched,
    clearFileResults,
    enrichAllProducts,
    stopEnrichAll,
    toggleSkipEnriched,
    downloadSummary,
    downloadDetailedResults,
    downloadImages,
    downloadDocuments
  } = useProductEnrichment();

  const isDownloadDisabled =
    !activeFileName || downloadLoading || !identifierHeadersSelected || Object.keys(enrichmentResults).length === 0;

  const handleEnrichAll = () => {
    const rows: ProductIdentifiersRow[] = activeImportedData!.data.map((row) => ({
      rowId: row.id,
      identifiers: getProductRowIdentifiers(row.id)
    }));

    enrichAllProducts({
      batchSize: enrichAllBatchSize,
      rows,
      fileName: activeFileName!,
      options: {
        enrichmentGroups,
        sourceBlacklist,
        sourceLimit,
        sources: sources.map((source) => source.name),
        imageSearchOptions,
        documentSearchOptions,
        identifiers: []
      }
    });
  };

  return (
    <div className="-mx-6 px-4 bg-white border-b border-gray-200">
      <div className="flex flex-col md:flex-row items-start md:items-center px-6 py-3">
        {/* Enrichment Controls */}
        <div className="flex items-center gap-2 flex-1">
          <span className="text-sm font-medium text-gray-500 mr-2">Controls:</span>
          <div className="flex items-center gap-2 border-r border-gray-200 pr-4">
            <Tooltip content="Stop enrichment process">
              <button
                className={clsx(
                  'p-1.5 rounded-md transition-colors',
                  enrichAllStopped || !enrichAllLoading
                    ? 'text-gray-400 cursor-not-allowed hover:bg-gray-50'
                    : 'text-red-600 hover:bg-red-50'
                )}
                onClick={stopEnrichAll}
                disabled={enrichAllStopped || !enrichAllLoading}
                aria-label="Stop enrichment"
              >
                <StopIcon className="w-5 h-5" />
              </button>
            </Tooltip>

            <Tooltip content={skipEnriched ? 'Skip enriched items enabled' : 'Skip enriched items disabled'}>
              <button
                className={clsx(
                  'p-1.5 rounded-md transition-colors',
                  skipEnriched ? 'text-blue-600 hover:bg-blue-50' : 'text-gray-600 hover:bg-gray-50'
                )}
                onClick={toggleSkipEnriched}
                aria-label="Toggle skip enriched"
              >
                <ChevronDoubleRightIcon className="w-5 h-5" />
              </button>
            </Tooltip>

            <Tooltip content={enrichAllLoading ? 'Enrichment in progress' : 'Start enrichment'}>
              <button
                className={clsx(
                  'p-1.5 rounded-md transition-colors',
                  !identifierHeadersSelected || enrichAllLoading
                    ? 'text-gray-400 cursor-not-allowed hover:bg-gray-50'
                    : 'text-green-600 hover:bg-green-50'
                )}
                disabled={!identifierHeadersSelected || enrichAllLoading}
                onClick={handleEnrichAll}
                aria-label="Start enrichment"
              >
                <PlayIcon className="w-5 h-5" />
              </button>
            </Tooltip>

            <div className="flex items-center gap-2 pl-2">
              <input
                type="number"
                className="w-16 px-2 py-1 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                value={enrichAllBatchSize}
                onChange={(e) => setEnrichAllBatchSize(parseInt(e.target.value, 10))}
                min="1"
                aria-label="Batch size"
              />
              <span className="text-xs text-gray-500">Batch Size</span>
            </div>
          </div>

          {/* Download buttons group */}
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium text-gray-500 mr-2">Actions:</span>
            <div className="flex items-center gap-1">
              <Tooltip content="Download summary results">
                <button
                  className={clsx(
                    'flex items-center gap-1 px-2.5 py-1 text-sm font-medium rounded-md transition-colors',
                    isDownloadDisabled
                      ? 'text-gray-400 cursor-not-allowed hover:bg-gray-50'
                      : 'text-blue-600 hover:bg-blue-50'
                  )}
                  disabled={isDownloadDisabled}
                  onClick={() => {
                    const rowIdentifiers = activeImportedData!.data.map((row) => ({
                      rowId: row.id,
                      identifiers: getProductRowIdentifiers(row.id)
                    }));
                    downloadSummary(activeFileName!, activeImportedData!.headers, rowIdentifiers);
                  }}
                >
                  <ArrowDownIcon className="w-4 h-4" />
                  Summary
                </button>
              </Tooltip>

              <Tooltip content="Download detailed results">
                <button
                  className={clsx(
                    'flex items-center gap-1 px-2.5 py-1 text-sm font-medium rounded-md transition-colors',
                    isDownloadDisabled
                      ? 'text-gray-400 cursor-not-allowed hover:bg-gray-50'
                      : 'text-blue-600 hover:bg-blue-50'
                  )}
                  disabled={isDownloadDisabled}
                  onClick={() => {
                    const rowIdentifiers = activeImportedData!.data.map((row) => ({
                      rowId: row.id,
                      identifiers: getProductRowIdentifiers(row.id)
                    }));
                    downloadDetailedResults(activeFileName!, activeImportedData!.headers, rowIdentifiers);
                  }}
                >
                  <ArrowDownIcon className="w-4 h-4" />
                  Detailed
                </button>
              </Tooltip>
            </div>

            {/* Media download buttons */}
            <div className="flex items-center gap-1 border-l border-gray-200 pl-4">
              <Tooltip content="Download enriched images">
                <button
                  className={clsx(
                    'flex items-center gap-1 px-2.5 py-1 text-sm font-medium rounded-md transition-colors',
                    isDownloadDisabled
                      ? 'text-gray-400 cursor-not-allowed hover:bg-gray-50'
                      : 'text-blue-600 hover:bg-blue-50'
                  )}
                  disabled={isDownloadDisabled}
                  onClick={() => {
                    const rowIdentifiers = activeImportedData!.data.map((row) => ({
                      rowId: row.id,
                      identifiers: getProductRowIdentifiers(row.id)
                    }));
                    downloadImages(activeFileName!, rowIdentifiers);
                  }}
                >
                  <PhotoIcon className="w-4 h-4" />
                  Images
                </button>
              </Tooltip>

              <Tooltip content="Download enriched documents">
                <button
                  className={clsx(
                    'flex items-center gap-1 px-2.5 py-1 text-sm font-medium rounded-md transition-colors',
                    isDownloadDisabled
                      ? 'text-gray-400 cursor-not-allowed hover:bg-gray-50'
                      : 'text-blue-600 hover:bg-blue-50'
                  )}
                  disabled={isDownloadDisabled}
                  onClick={() => {
                    const rowIdentifiers = activeImportedData!.data.map((row) => ({
                      rowId: row.id,
                      identifiers: getProductRowIdentifiers(row.id)
                    }));
                    downloadDocuments(activeFileName!, rowIdentifiers);
                  }}
                >
                  <DocumentIcon className="w-4 h-4" />
                  Documents
                </button>
              </Tooltip>
            </div>

            {/* Clear results button */}
            <div className="border-l border-gray-200 pl-4">
              <Tooltip content="Clear all results">
                <button
                  className={clsx(
                    'flex items-center gap-1 px-2.5 py-1 text-sm font-medium rounded-md transition-colors',
                    !activeFileName ||
                      enrichAllLoading ||
                      downloadLoading ||
                      Object.keys(enrichmentResults).length === 0
                      ? 'text-gray-400 cursor-not-allowed hover:bg-gray-50'
                      : 'text-red-600 hover:bg-red-50'
                  )}
                  disabled={
                    !activeFileName ||
                    enrichAllLoading ||
                    downloadLoading ||
                    Object.keys(enrichmentResults).length === 0
                  }
                  onClick={() => clearFileResults(activeFileName!)}
                >
                  <XMarkIcon className="w-4 h-4" />
                  Clear
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
