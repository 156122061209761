import {
  ImageSearchResult,
  SourceEnrichmentFieldResult,
  SourceEnrichmentGroupResult,
  SourceEnrichmentResponse,
  SourceEnrichmentSummaries,
  DocumentSearchResult
} from './types';

type StartProductEnrichmentAction = {
  type: 'START_PRODUCT_ENRICHMENT';
  payload: { fileName: string; rowId: string; enrichmentGroups: SourceEnrichmentGroupResult[] };
};

type ClearEnrichmentFileResultsAction = {
  type: 'CLEAR_ENRICHMENT_FILE_RESULTS';
  payload: { fileName: string };
};

type ClearEnrichmentRowResultAction = {
  type: 'CLEAR_ENRICHMENT_ROW_RESULT';
  payload: { fileName: string; rowId: string };
};

type UpsertEnrichmentRowCleanQueryAction = {
  type: 'UPSERT_ENRICHMENT_ROW_CLEAN_QUERY';
  payload: { fileName: string; rowId: string; cleanQuery: string };
};

type SetEnrichmentRowErrorAction = {
  type: 'SET_ENRICHMENT_ROW_ERROR';
  payload: { fileName: string; rowId: string; error: string };
};

type UpsertEnrichmentRowResultAction = {
  type: 'UPSERT_ENRICHMENT_ROW_RESULT';
  payload: { fileName: string; rowId: string; data: SourceEnrichmentResponse };
};

type UpsertEnrichmentRowResultSourceFieldsAction = {
  type: 'UPSERT_ENRICHMENT_ROW_RESULT_SOURCE_FIELDS';
  payload: {
    fileName: string;
    rowId: string;
    enrichmentGroups: SourceEnrichmentGroupResult[];
  };
};

type UpsertResultSummariesAction = {
  type: 'UPSERT_RESULT_SUMMARIES';
  payload: {
    fileName: string;
    rowId: string;
    resultSummaries: SourceEnrichmentSummaries;
  };
};

type UpsertEnrichmentRowResultImagesAction = {
  type: 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES';
  payload: { fileName: string; rowId: string; images: ImageSearchResult[] };
};

type UpsertEnrichmentRowResultDocumentsAction = {
  type: 'UPSERT_ENRICHMENT_ROW_RESULT_DOCUMENTS';
  payload: { fileName: string; rowId: string; documents: DocumentSearchResult[] };
};

type RemoveEnrichmentRowImageAction = {
  type: 'REMOVE_ENRICHMENT_ROW_IMAGE';
  payload: { fileName: string; rowId: string; imageIndex: number };
};

type RemoveEnrichmentRowDocumentAction = {
  type: 'REMOVE_ENRICHMENT_ROW_DOCUMENT';
  payload: { fileName: string; rowId: string; documentId: string };
};

type CompleteProductEnrichmentAction = {
  type: 'COMPLETE_PRODUCT_ENRICHMENT';
  payload: { fileName: string; rowId: string; duration: number };
};

type CompleteEnrichingFields = {
  type: 'COMPLETE_ENRICHING_FIELDS';
  payload: { fileName: string; rowId: string };
};

type UpdateEnrichmentFieldValueAction = {
  type: 'UPDATE_ENRICHMENT_FIELD_VALUE';
  payload: {
    fileName: string;
    rowId: string;
    groupName: string;
    fieldName: string;
    value: string;
  };
};

type EnrichmentResultsAction =
  | StartProductEnrichmentAction
  | ClearEnrichmentFileResultsAction
  | ClearEnrichmentRowResultAction
  | UpsertEnrichmentRowCleanQueryAction
  | SetEnrichmentRowErrorAction
  | UpsertEnrichmentRowResultAction
  | CompleteEnrichingFields
  | UpsertEnrichmentRowResultSourceFieldsAction
  | UpsertResultSummariesAction
  | UpsertEnrichmentRowResultImagesAction
  | UpsertEnrichmentRowResultDocumentsAction
  | RemoveEnrichmentRowDocumentAction
  | RemoveEnrichmentRowImageAction
  | CompleteProductEnrichmentAction
  | UpdateEnrichmentFieldValueAction;

export type RowEnrichmentResultState = {
  data?: SourceEnrichmentResponse;
  loading: boolean;
  error?: string;
};

export type FileEnrichmentResult = {
  [rowId: string]: RowEnrichmentResultState;
};

export type EnrichmentResultState = {
  [fileName: string]: FileEnrichmentResult;
};

export function enrichmentResultsReducer(
  state: EnrichmentResultState,
  action: EnrichmentResultsAction
): EnrichmentResultState {
  switch (action.type) {
    case 'START_PRODUCT_ENRICHMENT': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...(state[action.payload.fileName] || {}),
          [action.payload.rowId]: {
            loading: true,
            error: undefined,
            data: {
              groups: action.payload.enrichmentGroups
            }
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_CLEAN_QUERY': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              cleanQuery: action.payload.cleanQuery
            }
          }
        }
      };
    }

    case 'SET_ENRICHMENT_ROW_ERROR': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            loading: false,
            error: action.payload.error
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_RESULT': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName]?.[action.payload.rowId],
            data: action.payload.data,
            error: undefined
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_RESULT_SOURCE_FIELDS': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              groups: action.payload.enrichmentGroups
            }
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES': {
      const currentState = state[action.payload.fileName][action.payload.rowId];
      const currentImages = currentState?.data?.images || [];
      const currentHistory = currentState?.data?.imageHistory || [];

      // Add current images to history if they're not already there
      const newHistory = [...currentHistory];
      currentImages.forEach((img) => {
        if (!newHistory.some((h) => h.contentUrl === img.contentUrl)) {
          newHistory.push(img);
        }
      });

      // Add new images to history if they're not already there
      action.payload.images.forEach((img) => {
        if (!newHistory.some((h) => h.contentUrl === img.contentUrl)) {
          newHistory.push(img);
        }
      });

      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              images: action.payload.images,
              imageHistory: newHistory
            },
            error: undefined
          }
        }
      };
    }

    case 'UPSERT_RESULT_SUMMARIES': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              summary: action.payload.resultSummaries.summary,
              groupSummaries: action.payload.resultSummaries.groupSummaries
            }
          }
        }
      };
    }

    case 'UPSERT_ENRICHMENT_ROW_RESULT_DOCUMENTS': {
      const currentState = state[action.payload.fileName][action.payload.rowId];
      const currentDocuments = currentState?.data?.documents || [];
      const currentHistory = currentState?.data?.documentHistory || [];

      // Add current documents to history if they're not already there
      const newHistory = [...currentHistory];
      currentDocuments.forEach((doc) => {
        if (!newHistory.some((h) => h.id === doc.id)) {
          newHistory.push(doc);
        }
      });

      // Add new documents to history if they're not already there
      action.payload.documents.forEach((doc) => {
        if (!newHistory.some((h) => h.id === doc.id)) {
          newHistory.push(doc);
        }
      });

      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              documents: action.payload.documents,
              documentHistory: newHistory
            },
            error: undefined
          }
        }
      };
    }

    case 'REMOVE_ENRICHMENT_ROW_IMAGE': {
      const { fileName, rowId, imageIndex } = action.payload;
      const currentState = state[fileName][rowId];

      if (!currentState || !currentState.data || !currentState.data.images) {
        return state;
      }

      const updatedImages = [...currentState.data.images];
      updatedImages.splice(imageIndex, 1);

      return {
        ...state,
        [fileName]: {
          ...state[fileName],
          [rowId]: {
            ...currentState,
            data: {
              ...currentState.data,
              images: updatedImages
            }
          }
        }
      };
    }

    case 'REMOVE_ENRICHMENT_ROW_DOCUMENT': {
      const currentState = state[action.payload.fileName][action.payload.rowId];
      const currentDocuments = currentState?.data?.documents || [];

      return {
        ...state,
        [action.payload.fileName]: {
          ...state[action.payload.fileName],
          [action.payload.rowId]: {
            ...state[action.payload.fileName][action.payload.rowId],
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              documents: currentDocuments.filter((doc) => doc.id !== action.payload.documentId)
            }
          }
        }
      };
    }

    case 'COMPLETE_ENRICHING_FIELDS': {
      const { fileName, rowId } = action.payload;
      const currentState = state[fileName][rowId];

      if (!currentState || !currentState.data) {
        return state;
      }

      const groupsWithStats = currentState.data.groups.map((group) => {
        const { nullFields, nonNullFields } = splitEnrichmentGroupResultFields(group);
        const enrichedCount = nonNullFields.length;
        const nullCount = nullFields.length;
        const totalFieldsCount = enrichedCount + nullCount;
        const enrichedPct = totalFieldsCount > 0 ? Math.round((enrichedCount / totalFieldsCount) * 100) : 0;

        return {
          ...group,
          stats: {
            enrichedCount,
            enrichedPct,
            nullCount
          }
        };
      });

      const overallStats = calculateOverallStats(groupsWithStats);

      return {
        ...state,
        [fileName]: {
          ...state[fileName],
          [rowId]: {
            ...currentState,
            data: {
              ...currentState.data,
              groups: groupsWithStats,
              stats: overallStats
            }
          }
        }
      };
    }

    case 'COMPLETE_PRODUCT_ENRICHMENT': {
      return {
        ...state,
        [action.payload.fileName]: {
          ...(state[action.payload.fileName] || {}),
          [action.payload.rowId]: {
            ...state[action.payload.fileName]?.[action.payload.rowId],
            loading: false,
            error: undefined,
            data: {
              ...state[action.payload.fileName][action.payload.rowId].data!,
              processingTime: action.payload.duration
            }
          }
        }
      };
    }

    case 'CLEAR_ENRICHMENT_FILE_RESULTS': {
      const newState = { ...state };
      delete newState[action.payload.fileName];
      return newState;
    }

    case 'CLEAR_ENRICHMENT_ROW_RESULT': {
      const newState = { ...state };
      delete newState[action.payload.fileName][action.payload.rowId];
      return newState;
    }

    case 'UPDATE_ENRICHMENT_FIELD_VALUE': {
      const { fileName, rowId, groupName, fieldName, value } = action.payload;
      const currentState = state[fileName][rowId];

      if (!currentState || !currentState.data) {
        return state;
      }

      return {
        ...state,
        [fileName]: {
          ...state[fileName],
          [rowId]: {
            ...currentState,
            data: {
              ...currentState.data,
              groups: currentState.data.groups.map((group) => {
                if (group.groupName !== groupName) {
                  return group;
                }
                return {
                  ...group,
                  fields: group.fields.map((field) => {
                    if (field.fieldName !== fieldName) {
                      return field;
                    }
                    return {
                      ...field,
                      values: [
                        {
                          value,
                          sourceName: 'Manual Edit',
                          sourceUrl: window.location.href
                        },
                        ...field.values
                      ]
                    };
                  })
                };
              })
            }
          }
        }
      };
    }

    default:
      throw new Error(`unknown enrichment results action type`);
  }
}

export function splitEnrichmentGroupResultFields(group: SourceEnrichmentGroupResult) {
  const nullFields: SourceEnrichmentFieldResult[] = [];
  const nonNullFields: SourceEnrichmentFieldResult[] = [];

  for (const field of group.fields) {
    if (field.values.every((value) => value.value === null || value.value === 'null')) {
      nullFields.push(field);
    } else {
      const values = field.values.filter((value) => {
        return !!value.value && value.value !== 'null';
      });
      if (values.length > 0) {
        nonNullFields.push({
          ...field,
          values
        });
      }
    }
  }

  return { nullFields, nonNullFields };
}

function calculateOverallStats(groups: SourceEnrichmentGroupResult[]) {
  const enrichedCount = groups.reduce((acc, curr) => acc + curr.stats!.enrichedCount, 0);
  const nullCount = groups.reduce((acc, curr) => acc + curr.stats!.nullCount, 0);
  const totalFieldsCount = enrichedCount + nullCount;
  const enrichedPct = totalFieldsCount > 0 ? Math.round((enrichedCount / totalFieldsCount) * 100) : 0;

  return { enrichedCount, nullCount, enrichedPct };
}
