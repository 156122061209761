import { Link } from 'react-router-dom';
import { SubscriptionWrapper } from '../pages/Subscription/SubscriptionWrapper';
import { Subscription } from '../pages';

export const SUBSCRIPTION_ROUTES = [
  {
    path: '/subscription',
    element: <SubscriptionWrapper />,
    handle: {
      crumb: () => <Link to="/subscription/usage">Subscription</Link>
    },
    children: [
      {
        path: 'overview',
        element: <Subscription />,
        handle: {
          crumb: () => <Link to="/subscription/overview">Usage</Link>
        }
      }
    ]
  }
];
