import { useParams } from 'react-router-dom';
import { SectionHeader } from '../components/SectionHeader';
import { useEffect, useState } from 'react';
import { MDMProjectResultReviewStrategy } from '../types';
import { MDMProjectStrategyCard } from '../components/MDMProjectStrategyCard';
import { apiClient } from '../api';

export function MDMProjectResultReviewPage() {
  let { projectId, resultId } = useParams();

  useEffect(() => {
    document.title = 'MDM | Fireflai';
  });

  const [projectResultReviewStrategies, setProjectResultReviewStrategies] =
    useState<Array<MDMProjectResultReviewStrategy>>();

  useEffect(() => {
    apiClient.getProjectResultReviewStrategies(parseInt(projectId!), parseInt(resultId!)).then((strategies) => {
      setProjectResultReviewStrategies(strategies);
    });
  }, [projectId, resultId]);

  return (
    <div className="h-full flex flex-col p-8">
      <SectionHeader title={`Review results`} />

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Review Strategies</h2>
        <div className="grid grid-cols-2 gap-4">
          {projectResultReviewStrategies?.map((strategy) => (
            <MDMProjectStrategyCard key={strategy.id} strategy={strategy} />
          ))}
        </div>
      </div>
    </div>
  );
}
