import React from 'react';
import { ImageSearchOptions, ImageSize, ImageAspect } from './types';

interface ImageSearchConfigProps {
  value: ImageSearchOptions;
  onChange: (options: ImageSearchOptions) => void;
}

const imageSizes: ImageSize[] = ['All', 'Small', 'Medium', 'Large', 'Wallpaper'];
const imageAspects: ImageAspect[] = ['All', 'Square', 'Wide', 'Tall'];

export function ImageSearchConfig({ value, onChange }: ImageSearchConfigProps) {
  // Ensure we always have default values
  const currentValue = {
    count: value.count || 5,
    size: value.size || 'All',
    aspect: value.aspect || 'All'
  };

  return (
    <div className="space-y-4">
      <h3 className="text-sm font-medium text-gray-900">Image Search Configuration</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="imageCount" className="block text-sm font-medium text-gray-700">
            Number of Images
          </label>
          <input
            type="number"
            id="imageCount"
            min={1}
            max={50}
            value={currentValue.count}
            onChange={(e) => onChange({ ...currentValue, count: parseInt(e.target.value) })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="imageSize" className="block text-sm font-medium text-gray-700">
            Image Size
          </label>
          <select
            id="imageSize"
            value={currentValue.size}
            onChange={(e) => onChange({ ...currentValue, size: e.target.value as ImageSize })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            {imageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="imageAspect" className="block text-sm font-medium text-gray-700">
            Image Aspect
          </label>
          <select
            id="imageAspect"
            value={currentValue.aspect}
            onChange={(e) => onChange({ ...currentValue, aspect: e.target.value as ImageAspect })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          >
            {imageAspects.map((aspect) => (
              <option key={aspect} value={aspect}>
                {aspect}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
