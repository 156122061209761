import React, { useState, useEffect, useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  FolderIcon,
  DocumentTextIcon,
  TrashIcon,
  ArrowPathIcon,
  MagnifyingGlassIcon,
  ArrowUpTrayIcon,
  XMarkIcon,
  CalendarIcon,
  DocumentIcon,
  InformationCircleIcon,
  FolderPlusIcon,
  ExclamationTriangleIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline';
import { FileUploadModal } from '../../components';
import clsx from 'clsx';
import { useBlobStorage } from '../../hooks/useBlobStorage';
import { BlobFile } from '../../services/AzureBlobStorage';
import { useGetVaultTokenMutation } from '../../store/files';
import { toast } from 'react-toastify';
import { standardiseError, isFetchBaseQueryError } from '../../utils/errors';

export const AssetLibrary = () => {
  // const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState<'name' | 'size' | 'lastModified'>('lastModified');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [showUploadPanel, setShowUploadPanel] = useState(false);
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const [showNewDirectoryDialog, setShowNewDirectoryDialog] = useState(false);
  const [newDirectoryName, setNewDirectoryName] = useState('');
  const [isCreatingDirectory, setIsCreatingDirectory] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<BlobFile | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [getVaultToken] = useGetVaultTokenMutation();

  const {
    files,
    uploadFile,
    deleteFile,
    refreshFiles,
    navigateToDirectory,
    navigateUp,
    createDirectory,
    updateConfig,
    error: blobStorageError,
    isLoading
  } = useBlobStorage({
    sasToken: '',
    baseUrl: '',
    relativePath: ''
  });

  useEffect(() => {
    document.title = 'Asset Library | Fireflai';
  });

  // Handle blob storage errors
  useEffect(() => {
    if (blobStorageError) {
      if (isFetchBaseQueryError(blobStorageError)) {
        toast.error(standardiseError(blobStorageError));
      } else {
        toast.error('Failed to load files. Please try refreshing the page.');
      }
    }
  }, [blobStorageError]);

  // Fetch the vault token when the component mounts
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const result = await getVaultToken().unwrap();
        updateConfig({
          sasToken: result.token,
          baseUrl: result.baseUrl,
          relativePath: result.relativePath
        });
      } catch (error) {
        if (isFetchBaseQueryError(error)) {
          toast.error(standardiseError(error));
        } else {
          toast.error('Failed to fetch vault token. Please try again.');
        }
      }
    };
    fetchToken();
  }, [getVaultToken, updateConfig]);

  const handleFileUpload = async (newFiles: File[]) => {
    if (newFiles.length === 0) return;

    try {
      setIsUploading(true);

      for (const file of newFiles) {
        await uploadFile(file, () => { });
      }

      toast.success(`Successfully uploaded ${newFiles.length} file${newFiles.length > 1 ? 's' : ''}`);
      // Wait a moment to show completion status before closing
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShowUploadPanel(false);
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        toast.error(standardiseError(err));
      } else {
        toast.error('Failed to upload files. Please try again.');
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteClick = (file: BlobFile) => {
    setItemToDelete(file);
    setShowDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!itemToDelete) return;

    setIsDeleting(true);
    try {
      await deleteFile(itemToDelete.path);
      toast.success(`Successfully deleted ${itemToDelete.isDirectory ? 'directory' : 'file'} "${itemToDelete.name}"`);
      setShowDeleteDialog(false);
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(standardiseError(error));
      } else {
        toast.error('Failed to delete. Please try again.');
      }
    } finally {
      setIsDeleting(false);
      setItemToDelete(null);
    }
  };

  const handleDirectoryClick = (file: BlobFile) => {
    if (file.isDirectory) {
      if (file.name === '..') {
        navigateUp();
      } else {
        navigateToDirectory(file.path);
      }
    }
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return bytes + ' B';
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
    return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleSort = (field: 'name' | 'size' | 'lastModified') => {
    if (sortField === field) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        // Reset to default sort (lastModified desc)
        setSortField('lastModified');
        setSortDirection('desc');
      }
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Filter and sort files
  const filteredFiles = useMemo(() => {
    let result = [...files];

    // Apply search filter
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();
      result = result.filter((file) => file.name.toLowerCase().includes(query));
    }

    // Apply sorting
    result.sort((a, b) => {
      // Always sort directories first
      if (a.isDirectory !== b.isDirectory) {
        return a.isDirectory ? -1 : 1;
      }

      let comparison = 0;
      switch (sortField) {
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'size':
          comparison = a.size - b.size;
          break;
        case 'lastModified':
          comparison = a.lastModified.getTime() - b.lastModified.getTime();
          break;
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });

    return result;
  }, [files, searchQuery, sortField, sortDirection]);

  const handleCreateDirectory = async () => {
    if (!newDirectoryName.trim()) return;

    setIsCreatingDirectory(true);
    try {
      await createDirectory(newDirectoryName);
      toast.success(`Successfully created directory "${newDirectoryName}"`);
      setNewDirectoryName('');
      setShowNewDirectoryDialog(false);
    } catch (err) {
      if (isFetchBaseQueryError(err)) {
        toast.error(standardiseError(err));
      } else {
        toast.error('Failed to create directory. Please try again.');
      }
    } finally {
      setIsCreatingDirectory(false);
    }
  };

  const FilePreview = ({ file }: { file: BlobFile }) => {
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      if (!file.isDirectory && file.url) {
        // Create an object URL with no-cors mode
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
          setPreviewUrl(file.url);
        };
        img.onerror = () => {
          setError('Failed to load preview');
        };
        img.src = file.url;
      }
    }, [file]);

    if (file.isDirectory) {
      return (
        <div className="flex items-center justify-center w-16 h-16 bg-gray-100 rounded-lg">
          <FolderIcon className="h-8 w-8 text-gray-400" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center w-16 h-16 bg-gray-100 rounded-lg">
          <DocumentIcon className="h-8 w-8 text-gray-400" />
        </div>
      );
    }

    if (!previewUrl) {
      return (
        <div className="flex items-center justify-center w-16 h-16 bg-gray-100 rounded-lg">
          <DocumentIcon className="h-8 w-8 text-gray-400" />
        </div>
      );
    }

    return (
      <img src={previewUrl} alt={file.name} className="w-16 h-16 object-cover rounded-lg" crossOrigin="anonymous" />
    );
  };

  const handleRefresh = async () => {
    try {
      await refreshFiles();
    } catch (error) {
      if (isFetchBaseQueryError(error)) {
        toast.error(standardiseError(error));
      } else {
        toast.error('Failed to refresh files. Please try again.');
      }
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-100">
      {/* Command bar */}
      <div className="bg-white border-b border-gray-200 px-6 py-3 flex flex-wrap items-center justify-between gap-3">
        <div className="flex items-center gap-4">
          <h1 className="text-lg font-medium text-gray-900">Files</h1>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-64 pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search files..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="flex items-center gap-2">
          <button
            onClick={() => setShowNewDirectoryDialog(true)}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <FolderPlusIcon className="h-4 w-4 mr-1.5" />
            New Directory
          </button>

          <button
            onClick={() => setShowUploadPanel((prev) => !prev)}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArrowUpTrayIcon className="h-4 w-4 mr-1.5" />
            Upload
          </button>

          <button
            className="p-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            title="Refresh files"
            onClick={handleRefresh}
          >
            <ArrowPathIcon className="h-4 w-4" />
          </button>
        </div>
      </div>

      {/* Main content area with file detail panel */}
      <div className="flex-1 overflow-hidden flex">
        {/* Files table */}
        <div className={clsx('flex-1 p-6 overflow-auto transition-all duration-200', showDetailPanel && 'pr-2')}>
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
            {/* Table header */}
            <div className="px-6 py-4 border-b border-gray-200 bg-gray-50 flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {filteredFiles.length} {filteredFiles.length === 1 ? 'file' : 'files'}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-500">Sort by:</span>
                <button
                  className={clsx(
                    'text-xs font-medium px-2 py-1 rounded flex items-center',
                    sortField === 'name' ? 'text-blue-600 bg-blue-50' : 'text-gray-500 hover:bg-gray-50'
                  )}
                  onClick={() => handleSort('name')}
                >
                  Name
                  {sortField === 'name' &&
                    (sortDirection === 'asc' ? (
                      <ChevronUpIcon className="h-3 w-3 ml-1" />
                    ) : (
                      <ChevronDownIcon className="h-3 w-3 ml-1" />
                    ))}
                </button>
                <button
                  className={clsx(
                    'text-xs font-medium px-2 py-1 rounded flex items-center',
                    sortField === 'size' ? 'text-blue-600 bg-blue-50' : 'text-gray-500 hover:bg-gray-50'
                  )}
                  onClick={() => handleSort('size')}
                >
                  Size
                  {sortField === 'size' &&
                    (sortDirection === 'asc' ? (
                      <ChevronUpIcon className="h-3 w-3 ml-1" />
                    ) : (
                      <ChevronDownIcon className="h-3 w-3 ml-1" />
                    ))}
                </button>
                <button
                  className={clsx(
                    'text-xs font-medium px-2 py-1 rounded flex items-center',
                    sortField === 'lastModified' ? 'text-blue-600 bg-blue-50' : 'text-gray-500 hover:bg-gray-50'
                  )}
                  onClick={() => handleSort('lastModified')}
                >
                  Date
                  {sortField === 'lastModified' &&
                    (sortDirection === 'asc' ? (
                      <ChevronUpIcon className="h-3 w-3 ml-1" />
                    ) : (
                      <ChevronDownIcon className="h-3 w-3 ml-1" />
                    ))}
                </button>
              </div>
            </div>

            {/* File listing */}
            {isLoading ? (
              <div className="flex items-center justify-center py-12">
                <div className="flex flex-col items-center">
                  <ArrowPathIcon className="h-8 w-8 text-blue-500 animate-spin" />
                  <p className="mt-2 text-sm text-gray-500">Loading files...</p>
                </div>
              </div>
            ) : filteredFiles.length > 0 ? (
              <div className="overflow-auto max-h-[calc(100vh-250px)]">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Size
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Modified
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredFiles.map((file) => (
                      <tr
                        key={file.path}
                        className={clsx(
                          'transition-colors hover:bg-gray-50 cursor-pointer',
                          file.isDirectory ? 'cursor-pointer' : ''
                        )}
                        onClick={() => {
                          if (file.isDirectory) {
                            handleDirectoryClick(file);
                          } else {
                            setShowDetailPanel(true);
                          }
                        }}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            {file.isDirectory ? (
                              <FolderIcon className="h-5 w-5 text-gray-400" />
                            ) : (
                              <DocumentTextIcon className="h-5 w-5 text-gray-400" />
                            )}
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{file.name}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {file.isDirectory ? '-' : formatFileSize(file.size)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{formatDate(file.lastModified)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          {file.name !== '..' && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(file);
                              }}
                              className="text-red-600 hover:text-red-900"
                            >
                              <TrashIcon className="h-5 w-5" />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-12 px-6 bg-white">
                {searchQuery ? (
                  <div>
                    <MagnifyingGlassIcon className="mx-auto h-12 w-12 text-gray-300" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No files match your search</h3>
                    <p className="mt-1 text-sm text-gray-500">Try a different search term or clear your search.</p>
                  </div>
                ) : (
                  <div>
                    <FolderIcon className="mx-auto h-12 w-12 text-gray-300" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No files in this directory</h3>
                    <p className="mt-1 text-sm text-gray-500">Get started by uploading a CSV file.</p>
                    <button
                      onClick={() => setShowUploadPanel(true)}
                      className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <ArrowUpTrayIcon className="h-5 w-5 mr-2" />
                      Upload Files
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Right side detail panel - appears when a file is selected */}
        {showDetailPanel && filteredFiles.length > 0 && (
          <div className="w-80 bg-white border-l border-gray-200 shadow-lg h-full flex flex-col transition-all duration-200 ease-in-out">
            {/* Panel header */}
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
              <h3 className="text-lg font-medium text-gray-900">File Details</h3>
              <button
                onClick={() => {
                  setShowDetailPanel(false);
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>

            {/* File information */}
            <div className="p-6 overflow-auto flex-1">
              <div className="flex items-center mb-6">
                <div className="h-12 w-12 flex items-center justify-center bg-blue-100 rounded-full">
                  <FilePreview file={filteredFiles[0]} />
                </div>
                <div className="ml-4">
                  <h4
                    className="text-base font-medium text-gray-900 truncate max-w-[200px]"
                    title={filteredFiles[0].name}
                  >
                    {filteredFiles[0].name}
                  </h4>
                  <p className="text-sm text-gray-500">{formatFileSize(filteredFiles[0].size)}</p>
                </div>
              </div>

              {/* Detailed file information */}
              <div className="bg-gray-50 rounded-lg p-4 mb-6">
                <h5 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-3">File Information</h5>

                <div className="space-y-3">
                  <div className="flex items-start">
                    <CalendarIcon className="h-5 w-5 text-gray-400 mr-3 mt-0.5" />
                    <div>
                      <p className="text-xs text-gray-500">Last Modified</p>
                      <p className="text-sm text-gray-900">{formatDate(new Date(filteredFiles[0].lastModified))}</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <DocumentIcon className="h-5 w-5 text-gray-400 mr-3 mt-0.5" />
                    <div>
                      <p className="text-xs text-gray-500">Type</p>
                      <p className="text-sm text-gray-900">CSV File</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <InformationCircleIcon className="h-5 w-5 text-gray-400 mr-3 mt-0.5" />
                    <div>
                      <p className="text-xs text-gray-500">Status</p>
                      <p className="text-sm font-medium text-green-600">Ready for processing</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add workflow information that was previously in the cards */}
              {/* <div className="bg-gray-50 rounded-lg p-4 mb-6">
                <h5 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-3">
                  Workflow Information
                </h5>
                <ul className="space-y-2 text-sm text-gray-600">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                    <p>MDM cleans and deduplicates customer data</p>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                    <p>MDM V2 provides advanced matching capabilities</p>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                    <p>Enrichment enhances your data with additional information</p>
                  </li>
                </ul>
              </div> */}

              {/* Actions */}
              {/* <div className="mb-6">
                <h5 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-3">Available Actions</h5>

                <div className="space-y-3">
                  <button
                    onClick={() => navigate('/mdm')}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <PlayIcon className="h-5 w-5 mr-1.5" />
                    Run MDM
                  </button>

                  <button
                    onClick={() => navigate('/mdmv2')}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <PlayIcon className="h-5 w-5 mr-1.5" />
                    Run MDM V2
                  </button>

                  <button
                    onClick={() => navigate('/enrichment')}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    <PlayIcon className="h-5 w-5 mr-1.5" />
                    Run Enrichment
                  </button>
                </div>
              </div> */}

              {/* Danger zone */}
              <div>
                <h5 className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-3">File Management</h5>

                <button
                  onClick={() => handleDeleteClick(filteredFiles[0])}
                  className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <TrashIcon className="h-5 w-5 mr-1.5" />
                  Delete File
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* File Upload Modal */}
      <FileUploadModal
        isOpen={showUploadPanel}
        onClose={() => {
          setShowUploadPanel(false);
          setIsUploading(false);
        }}
        onFileAdd={handleFileUpload}
        isUploading={isUploading}
        onCancel={() => {
          setShowUploadPanel(false);
          setIsUploading(false);
        }}
      />

      {/* New Directory Dialog */}
      {showNewDirectoryDialog && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">Create New Directory</h3>
                <button onClick={() => setShowNewDirectoryDialog(false)} className="text-gray-400 hover:text-gray-500">
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="px-6 py-4">
              <div className="mb-4">
                <label htmlFor="directoryName" className="block text-sm font-medium text-gray-700">
                  Directory Name
                </label>
                <input
                  type="text"
                  id="directoryName"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={newDirectoryName}
                  onChange={(e) => setNewDirectoryName(e.target.value)}
                  placeholder="Enter directory name"
                  autoFocus
                />
              </div>
            </div>
            <div className="px-6 py-4 bg-gray-50 rounded-b-lg flex justify-end gap-3">
              <button
                onClick={() => setShowNewDirectoryDialog(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateDirectory}
                disabled={!newDirectoryName.trim() || isCreatingDirectory}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isCreatingDirectory ? 'Creating...' : 'Create Directory'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {showDeleteDialog && itemToDelete && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium text-gray-900">
                  Delete {itemToDelete.isDirectory ? 'Directory' : 'File'}
                </h3>
                <button onClick={() => setShowDeleteDialog(false)} className="text-gray-400 hover:text-gray-500">
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="px-6 py-4">
              <div className="flex items-center mb-4">
                <ExclamationTriangleIcon className="h-5 w-5 text-red-500 mr-2" />
                <p className="text-sm text-gray-700">
                  Are you sure you want to delete {itemToDelete.isDirectory ? 'the directory' : 'the file'} "
                  {itemToDelete.name}"?
                  {itemToDelete.isDirectory && ' This will delete all contents of the directory.'}
                </p>
              </div>
            </div>
            <div className="px-6 py-4 bg-gray-50 flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteDialog(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                disabled={isDeleting}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
