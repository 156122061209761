import { CircleStackIcon } from '@heroicons/react/24/solid';
import { MDMProject } from '../types'; // Adjust the import path as needed
import { Link } from 'react-router-dom';

interface MDMProjectCardProps {
  project: MDMProject;
}

export const MDMProjectCard: React.FC<MDMProjectCardProps> = ({ project }) => {
  return (
    <div
      className="bg-white rounded-lg p-6 hover:shadow-lg transition-shadow"
      style={{
        boxShadow: '0 4px 6px -4px rgba(0,0,0,0.1), 0 -4px 6px -4px rgba(0,0,0,0.1)'
      }}
    >
      {/* Project Header */}
      <div className="mb-4">
        <h3 className="text-xl font-semibold text-gray-900">{project.name}</h3>
        <p className="text-gray-600 mt-1">{project.description}</p>
      </div>

      {/* Datasets Section */}
      <div className="mt-4">
        <h4 className="text-sm font-medium text-gray-700 mb-2">Datasets</h4>
        <div className="grid grid-cols-2 gap-4">
          {project.datasets.map((dataset) => (
            <div
              key={dataset.id}
              className="flex flex-col items-center p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors"
            >
              <CircleStackIcon className="h-6 w-6 text-blue-500 mb-2" />
              <span className="text-sm text-gray-700 text-center">{dataset.name}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Project Footer */}
      <div className="mt-4 pt-4 border-t border-gray-200">
        <Link
          className="mt-2 w-full px-4 py-2 bg-blue-500 text-white text-sm rounded hover:bg-blue-600 transition-colors"
          to={`/demo/mdmv2/${project.id}`}
        >
          View Project
        </Link>
      </div>
    </div>
  );
};
