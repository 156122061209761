import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TagTypes } from './store.constants';
import { env } from '../env';
import { prepHeaders } from './utils';

type Subscription = {
  id: string;
  name: string;
  tier: string;
  startsOn: string;
  createdAt: string;
  status: 'active' | 'inactive';
  tenantId: string;
  endsOn: string;
  products: Array<{
    productName: string;
    enabled: boolean;
  }>;
};

export const subscriptionsApi = createApi({
  reducerPath: 'subscriptions',
  baseQuery: fetchBaseQuery({
    baseUrl: `${env.API_FQDN}/subscription/portal`,
    prepareHeaders: prepHeaders
  }),
  tagTypes: [TagTypes.Subscriptions],
  endpoints: (builder) => ({
    getActiveSubscription: builder.query<Subscription | null, void>({
      query: () => {
        return {
          url: `subscriptions/active`
        };
      },
      providesTags: [TagTypes.Subscriptions]
    })
  })
});

export const { useGetActiveSubscriptionQuery } = subscriptionsApi;
