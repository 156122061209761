/**
 * SharedFileStorage service for handling file operations across different components of the application
 */

export interface StoredFile {
  name: string;
  size: number;
  lastModified: number;
  data?: any; // Optional actual data content of the file
}

export interface FileStorageState {
  files: Record<string, StoredFile>;
  activeFileName: string | null;
}

const LOCAL_STORAGE_KEY = 'sharedFileStorage';

/**
 * Get the current state of shared file storage
 */
export const getSharedFileStorage = (): FileStorageState => {
  const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!storedState) {
    return {
      files: {},
      activeFileName: null
    };
  }

  try {
    return JSON.parse(storedState);
  } catch (error) {
    // If there's an error parsing the stored state, clear it and return default state
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    return {
      files: {},
      activeFileName: null
    };
  }
};

/**
 * Save the state of shared file storage
 */
export const saveSharedFileStorage = (state: FileStorageState): void => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

/**
 * Add a file to storage
 */
export const addFileToStorage = (file: File, fileData?: any): void => {
  const currentState = getSharedFileStorage();

  currentState.files[file.name] = {
    name: file.name,
    size: file.size,
    lastModified: file.lastModified,
    data: fileData
  };

  saveSharedFileStorage(currentState);
};

/**
 * Remove a file from storage
 */
export const removeFileFromStorage = (fileName: string): void => {
  const currentState = getSharedFileStorage();

  if (currentState.files[fileName]) {
    delete currentState.files[fileName];

    // Reset activeFileName if we're deleting the active file
    if (currentState.activeFileName === fileName) {
      currentState.activeFileName = null;
    }

    saveSharedFileStorage(currentState);
  }
};

/**
 * Set the active file name
 */
export const setActiveFileName = (fileName: string | null): void => {
  const currentState = getSharedFileStorage();
  currentState.activeFileName = fileName;
  saveSharedFileStorage(currentState);
};

/**
 * Get the active file name
 */
export const getActiveFileName = (): string | null => {
  return getSharedFileStorage().activeFileName;
};

/**
 * Get the active file data
 */
export const getActiveFileData = (): any | null => {
  const state = getSharedFileStorage();
  if (!state.activeFileName) return null;

  return state.files[state.activeFileName]?.data || null;
};

/**
 * Get all stored files
 */
export const getAllStoredFiles = (): Record<string, StoredFile> => {
  return getSharedFileStorage().files;
};

/**
 * Clear all stored files
 */
export const clearAllStoredFiles = (): void => {
  saveSharedFileStorage({
    files: {},
    activeFileName: null
  });
};
