export const env = {
  API_FQDN: process.env.REACT_APP_API_FQDN ?? 'https://api.uk.staging.fireflai.ai/ctrl',
  API_ENDPOINT_APP: process.env.REACT_APP_API_ENDPOINT_APP ?? 'https://api.uk.staging.fireflai.ai/app',
  API_ENDPOINT_ENRICHMENT:
    process.env.REACT_APP_API_ENDPOINT_ENRICHMENT ?? 'https://api.uk.staging.fireflai.ai/product-enrichment',
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN ?? 'fireflai-staging.uk.auth0.com',
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID ?? 'SA1wt1Vc5EsTkI1LixN41OD0i3odil82',
  AUTH_API_AUDIENCE: process.env.REACT_APP_AUTH_API_AUDIENCE ?? 'platform.uk.staging.fireflai.ai',
  APP_FQDN: process.env.REACT_APP_APP_FQDN ?? 'http://localhost:8080',
  REACT_APP_AUTH0_CALLBACK_URL: process.env.REACT_APP_AUTH0_CALLBACK_URL ?? 'http://localhost:8080/login-callback',
  REACT_APP_MDM_REVIEW_API_FQDN: process.env.REACT_APP_MDM_REVIEW_API_FQDN ?? 'http://localhost:8000'
};
