import { MaxAttemptsExceededError } from '../errors';

type RetryFlag = 'RETRY_ACTION';
export const retryFlag: RetryFlag = 'RETRY_ACTION';

type ActionFunc<T> = (attemptNumber: number) => Promise<T | RetryFlag>;

const repeat = async <T>(action: ActionFunc<T>, maxAttempts: number): Promise<T> => {
  for (let i = 0; i < maxAttempts; i++) {
    const result = await action(i + 1);
    if (result !== retryFlag) {
      return result;
    }
  }

  throw new MaxAttemptsExceededError();
};

export default repeat;
