import { Link } from 'react-router-dom';
import { MDMProjectResultReviewStrategy, MDMProjectReviewStrategy } from '../types';

type Props = {
  strategy: MDMProjectReviewStrategy | MDMProjectResultReviewStrategy;
};

export function MDMProjectStrategyCard({ strategy }: Props) {
  return (
    <div className="border rounded-md shadow p-4 flex flex-col">
      <div className="flex-grow">
        <h3 className="text-lg font-bold">{strategy.name}</h3>
        <p className="mt-2 text-sm">{strategy.description}</p>
        {strategy.strategy && (
          <div>
            <h4 className="text-base font-semibold mt-4">Strategy Details</h4>
            <div className="text-sm">
              <span className="font-semibold">Filters:</span>
              <ul className="list-disc list-inside">
                {strategy.strategy.filters.map((filter, i) => (
                  <li key={i}>
                    {filter.fieldName} {filter.op} {filter.value}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-2 text-sm">
              <span className="font-semibold">Validate Fields:</span>
              <ul className="list-disc list-inside">
                {strategy.strategy.validateFields.map((field, i) => (
                  <li key={i}>{field}</li>
                ))}
              </ul>
            </div>
            <div className="mt-2 flex flex-col text-sm">
              <span className="font-semibold">Sample</span>
              <span>
                {'count' in strategy.strategy.sample
                  ? `${strategy.strategy.sample.count} items`
                  : `${strategy.strategy.sample.percentage}% items`}
              </span>
            </div>
          </div>
        )}
      </div>

      {'itemsReviewedCount' in strategy && 'totalItemsCount' in strategy && (
        <div className="pt-4">
          <div className="w-full bg-gray-200 h-2 rounded">
            <div
              className="bg-blue-500 h-2 rounded"
              style={{ width: `${(strategy.itemsReviewedCount / strategy.totalItemsCount) * 100}%` }}
            ></div>
          </div>
          <p className="text-xs text-gray-500 mt-1">
            {Math.round((strategy.itemsReviewedCount / strategy.totalItemsCount) * 100)}% completed
          </p>
        </div>
      )}

      {'mdmProjectResultId' in strategy && strategy.itemsReviewedCount < strategy.totalItemsCount && (
        <div className="pt-4">
          <Link
            className="bg-blue-500 text-white px-4 py-2 text-sm rounded hover:bg-blue-600 transition-colors"
            to={`/demo/mdmv2/${strategy.mdmProjectId}/result/${strategy.mdmProjectResultId}/strategy/${strategy.id}`}
          >
            Continue review
          </Link>
        </div>
      )}
    </div>
  );
}
