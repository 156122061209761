import { BlobServiceClient } from '@azure/storage-blob';

export interface BlobStorageConfig {
  sasToken: string;
  baseUrl: string;
  relativePath: string; // The root path that should appear as root to the user
}

export interface BlobFile {
  name: string; // Display name (last part of path)
  path: string; // Full path
  size: number;
  lastModified: Date;
  url: string;
  isDirectory: boolean;
}

class BlobStorageError extends Error {
  constructor(message: string, public originalError?: any) {
    super(message);
    this.name = 'BlobStorageError';
  }
}

export class AzureBlobStorageService {
  private blobServiceClient: BlobServiceClient;
  private containerClient: any;
  private config: BlobStorageConfig;
  private currentPath: string; // The current path relative to the relativePath

  constructor(config: BlobStorageConfig) {
    // Normalize the relativePath by removing leading/trailing slashes
    this.config = {
      ...config,
      relativePath: config.relativePath.replace(/^\/+|\/+$/g, '')
    };
    this.currentPath = ''; // Start at the relativePath root
    const urlParts = config.baseUrl.split('/');
    const storageAccountUrl = urlParts.slice(0, -1).join('/');
    const containerName = urlParts[urlParts.length - 1];

    this.blobServiceClient = new BlobServiceClient(`${storageAccountUrl}?${config.sasToken}`);
    this.containerClient = this.blobServiceClient.getContainerClient(containerName);
  }

  /**
   * Get the current directory path
   */
  getCurrentPath(): string {
    return this.currentPath;
  }

  /**
   * Navigate to a directory
   */
  navigateToDirectory(path: string): void {
    // If path is empty, we're at root (the relativePath)
    if (!path) {
      this.currentPath = '';
      return;
    }

    // Clean the path by removing leading/trailing slashes
    const cleanPath = path.replace(/^\/+|\/+$/g, '');

    // If path starts with /, it's relative to root
    if (path.startsWith('/')) {
      this.currentPath = cleanPath;
      return;
    }

    // Otherwise, it's relative to current path
    const cleanCurrentPath = this.currentPath.replace(/^\/+|\/+$/g, '');
    this.currentPath = cleanCurrentPath ? `${cleanCurrentPath}/${cleanPath}` : cleanPath;
  }

  /**
   * Navigate up one directory level
   */
  navigateUp(): void {
    // If we're at root (empty currentPath), stay at root
    if (!this.currentPath) {
      return;
    }

    // Remove trailing slash if present
    const cleanPath = this.currentPath.replace(/\/$/, '');
    const parts = cleanPath.split('/');
    parts.pop(); // Remove the last part
    this.currentPath = parts.join('/');
  }

  /**
   * Get the display name from a full path
   */
  private getDisplayName(path: string): string {
    // Remove trailing slash if present
    const cleanPath = path.replace(/\/$/, '');
    // Get the last part of the path
    const parts = cleanPath.split('/');
    return parts[parts.length - 1];
  }

  /**
   * Get the full Azure path by combining relativePath and current path
   */
  private getFullPath(path: string = ''): string {
    const parts = [this.config.relativePath];
    if (this.currentPath) {
      // Remove any leading/trailing slashes from currentPath
      const cleanCurrentPath = this.currentPath.replace(/^\/+|\/+$/g, '');
      if (cleanCurrentPath) parts.push(cleanCurrentPath);
    }
    if (path) {
      // Remove any leading/trailing slashes from path
      const cleanPath = path.replace(/^\/+|\/+$/g, '');
      if (cleanPath) parts.push(cleanPath);
    }
    return parts.join('/');
  }

  /**
   * List all files and directories in the current path
   */
  async listFiles(): Promise<BlobFile[]> {
    const files: BlobFile[] = [];

    // Add parent directory if not at root
    if (this.currentPath) {
      // Remove trailing slash if present and get parent path
      const cleanPath = this.currentPath.replace(/\/+$/, '');
      const parts = cleanPath.split('/');
      parts.pop(); // Remove the last part
      const parentPath = parts.join('/');
      files.push({
        name: '..',
        path: parentPath || '', // Use empty string for root
        size: 0,
        lastModified: new Date(),
        url: '',
        isDirectory: true
      });
    }

    const fullRelativePath = this.getFullPath();
    // Normalize the path to prevent double slashes and ensure single trailing slash
    const normalizedRelativePath = fullRelativePath.replace(/\/+/g, '/').replace(/\/+$/, '');
    const listRelativePath = `${normalizedRelativePath}/`;

    // First, get all blobs to check for directory markers
    const allBlobs = await this.listBlobs(normalizedRelativePath);

    // Create a set of directory paths (those that have a .directory marker)
    const directoryPaths = new Set<string>();
    for (const blob of allBlobs) {
      if (blob.name.endsWith('/.directory')) {
        const dirPath = blob.name.slice(0, -11); // Remove '/.directory'
        directoryPaths.add(dirPath);
      }
    }

    // Now process the blobs for display
    for (const blob of allBlobs) {
      // Skip the .directory marker files themselves
      if (blob.name.endsWith('/.directory')) {
        continue;
      }

      // Get the relative path and name
      const relativePath = blob.name.slice(listRelativePath.length);
      const name = relativePath.split('/')[0];

      // Skip if it's a parent directory entry
      if (name === '..') {
        continue;
      }

      // Check if it's a direct child by looking at the path without trailing slash
      const pathWithoutTrailingSlash = relativePath.replace(/\/$/, '');
      const isDirectChild = !pathWithoutTrailingSlash.includes('/', 1);

      if (isDirectChild) {
        // Check if this is a directory by looking for a .directory marker
        const isDirectory = directoryPaths.has(blob.name.replace(/\/$/, ''));

        if (isDirectory) {
          files.push({
            name,
            path: relativePath,
            size: 0,
            lastModified: new Date(),
            url: '',
            isDirectory: true
          });
        } else {
          // Get the blob properties to get the actual size and last modified date
          const blockBlobClient = this.containerClient.getBlockBlobClient(blob.name);
          const properties = await blockBlobClient.getProperties();

          files.push({
            name,
            path: relativePath,
            size: properties.contentLength || 0,
            lastModified: properties.lastModified || new Date(),
            url: this.getFileUrl(relativePath),
            isDirectory: false
          });
        }
      }
    }

    return files;
  }

  /**
   * Delete a file or directory
   * If it's a directory, it will recursively delete all contents
   */
  async deleteFile(path: string): Promise<void> {
    const fullPath = this.getFullPath(path);

    try {
      // First, check if this is a directory by looking for a .directory marker
      const directoryMarkerPath = `${fullPath}/.directory`;
      const directoryMarkerClient = this.containerClient.getBlockBlobClient(directoryMarkerPath);

      try {
        // Try to get the directory marker
        await directoryMarkerClient.getProperties();
        // If we get here, it's a directory

        // List all blobs in the directory first
        const blobs = await this.listBlobs(fullPath);

        // Delete each blob in the directory
        for (const blob of blobs) {
          // Skip the directory marker as we'll delete it last
          if (blob.name === directoryMarkerPath) {
            continue;
          }
          const blockBlobClient = this.containerClient.getBlockBlobClient(blob.name);
          await blockBlobClient.delete();
        }

        // Delete the directory marker
        await directoryMarkerClient.delete();

        // Finally, delete the directory itself
        const directoryClient = this.containerClient.getBlockBlobClient(fullPath);
        await directoryClient.delete();
        return;
      } catch (error: any) {
        // If we get a 404, it's not a directory marker, so it's a file
        if (error.statusCode !== 404) {
          throw error;
        }
      }

      // If we get here, it's a file
      const blockBlobClient = this.containerClient.getBlockBlobClient(fullPath);
      await blockBlobClient.delete();
    } catch (error) {
      throw new BlobStorageError('Failed to delete file', error);
    }
  }

  /**
   * Delete a directory and all its contents recursively
   */
  private async deleteDirectory(dirPath: string): Promise<void> {
    const fullPath = this.getFullPath(dirPath);

    try {
      // List all blobs in the directory
      const blobs = await this.listBlobs(fullPath);

      // Delete each blob
      for (const blob of blobs) {
        const blockBlobClient = this.containerClient.getBlockBlobClient(blob.name);
        await blockBlobClient.delete();
      }
    } catch (error) {
      throw new BlobStorageError('Failed to delete directory', error);
    }
  }

  /**
   * List all blobs under a given path
   */
  private async listBlobs(path: string): Promise<{ name: string }[]> {
    const blobs: { name: string }[] = [];

    try {
      // Ensure the path ends with a slash for directory listing
      const listPath = path.endsWith('/') ? path : `${path}/`;

      for await (const blob of this.containerClient.listBlobsFlat({
        prefix: listPath,
        includeMetadata: true
      })) {
        // Only include blobs that are direct children or in the directory
        if (blob.name.startsWith(listPath)) {
          blobs.push({ name: blob.name });
        }
      }
    } catch (error) {
      throw new BlobStorageError('Failed to list blobs', error);
    }

    return blobs;
  }

  /**
   * Get a file's URL
   */
  getFileUrl(path: string): string {
    const fullPath = this.getFullPath(path);
    return `${this.config.baseUrl}/${fullPath}?${this.config.sasToken}`;
  }

  /**
   * Upload a file to blob storage
   */
  async uploadFile(file: File, onProgress?: (progress: number) => void): Promise<void> {
    const fullPath = this.getFullPath(file.name);
    const blockBlobClient = this.containerClient.getBlockBlobClient(fullPath);
    const url = blockBlobClient.url;

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable && onProgress) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress);
        }
      });

      xhr.addEventListener('load', () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve();
        } else {
          reject(new Error(`Upload failed with status ${xhr.status}`));
        }
      });

      xhr.addEventListener('error', () => {
        reject(new Error('Upload failed'));
      });

      xhr.open('PUT', url);
      xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
  }

  /**
   * Create a new directory
   */
  async createDirectory(dirName: string): Promise<void> {
    // Create a .directory marker file to make the directory visible
    const fullPath = this.getFullPath(`${dirName}/.directory`);
    const blockBlobClient = this.containerClient.getBlockBlobClient(fullPath);
    await blockBlobClient.uploadData(new Uint8Array(0), {
      blobHTTPHeaders: {
        blobContentType: 'application/x-directory'
      }
    });
  }
}
