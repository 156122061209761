import Papa from 'papaparse';
import { generateUUID } from '../../utils/uuid';
import { EnrichmentDataTableHeader, EnrichmentDataTableRow } from './types';
import { escapeCsvValue } from './output';

export async function parseCsvFile(
  file: File
): Promise<{ headers: EnrichmentDataTableHeader[]; data: EnrichmentDataTableRow[] }> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results) => {
        if (results.data && results.data.length > 0) {
          const [headerRow, ...dataRows] = results.data as string[][];

          if (headerRow && dataRows) {
            const headers = headerRow.map((name) => ({ id: generateUUID(), name, kind: null }));
            const data: EnrichmentDataTableRow[] = dataRows.map((row) => ({
              cells: row.map((value) => ({ id: generateUUID(), value })),
              id: generateUUID()
            }));
            resolve({ headers, data });
          } else {
            reject(new Error('No valid headers found in the CSV file.'));
          }
        } else {
          reject(new Error('Failed to parse headers from the CSV file.'));
        }
      },
      error: (_error) => {
        reject(new Error('Error parsing CSV file. Please check the file format.'));
      }
    });
  });
}

/**
 * Parse CSV content from a string
 * @param csvString The CSV content as a string
 * @returns Promise with parsed headers and data
 */
export async function parseCsvString(
  csvString: string
): Promise<{ headers: EnrichmentDataTableHeader[]; data: EnrichmentDataTableRow[] }> {
  return new Promise((resolve, reject) => {
    const results = Papa.parse(csvString, {
      skipEmptyLines: true
    });

    if (results.data && results.data.length > 0) {
      const [headerRow, ...dataRows] = results.data as string[][];

      if (headerRow && dataRows) {
        const headers = headerRow.map((name) => ({ id: generateUUID(), name, kind: null }));
        const data: EnrichmentDataTableRow[] = dataRows.map((row) => ({
          cells: row.map((value) => ({ id: generateUUID(), value })),
          id: generateUUID()
        }));
        resolve({ headers, data });
      } else {
        reject(new Error('No valid headers found in the CSV data.'));
      }
    } else {
      reject(new Error('Failed to parse headers from the CSV data.'));
    }
  });
}

export function convertToCsvString(headerRow: string[], dataRows: string[][]): string {
  return [headerRow.join(','), ...dataRows.map((row) => row.map((value) => escapeCsvValue(value)).join(','))].join(
    '\n'
  );
}
