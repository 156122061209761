import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MDMProject, MDMProjectResult, MDMProjectReviewStrategy } from '../types';
import { SectionHeader } from '../components/SectionHeader';
import { MDMProjectStrategyCard } from '../components/MDMProjectStrategyCard';
import { MDMProjectResultsTable } from '../components/MDMProjectResultsTable';
import { apiClient } from '../api';

export function MDMProjectPage() {
  let { projectId } = useParams();
  const [project, setProject] = useState<MDMProject>();
  const [projectResults, setProjectResults] = useState<Array<MDMProjectResult>>();
  const [projectReviewStrategies, setProjectReviewStrategies] = useState<Array<MDMProjectReviewStrategy>>();

  useEffect(() => {
    document.title = 'MDM | Fireflai';
  });

  useEffect(() => {
    apiClient.getProject(parseInt(projectId!)).then((project) => {
      setProject(project);
    });
    apiClient.getProjectResults(parseInt(projectId!)).then((results) => {
      setProjectResults(results);
    });
    apiClient.getProjectReviewStrategies(parseInt(projectId!)).then((strategies) => {
      setProjectReviewStrategies(strategies);
    });
  }, [projectId]);

  if (!project || !projectResults || !projectReviewStrategies) {
    return null;
  }

  return (
    <div className="h-full flex flex-col p-8">
      <SectionHeader title={`Project: ${project.name}`} />

      {/* Project Runs Section */}
      <h2 className="text-xl font-semibold mb-2 mt-4">Project Runs</h2>
      <MDMProjectResultsTable results={projectResults} />

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Review Strategies</h2>
        <div className="grid grid-cols-2 gap-4">
          {projectReviewStrategies?.map((strategy) => <MDMProjectStrategyCard key={strategy.id} strategy={strategy} />)}
        </div>
      </div>
    </div>
  );
}
