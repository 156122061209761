import { useState, useEffect, useCallback } from 'react';
import { AzureBlobStorageService, BlobFile } from '../services/AzureBlobStorage';

interface BlobStorageConfig {
  sasToken: string;
  baseUrl: string;
  relativePath: string;
}

export const useBlobStorage = (initialConfig: BlobStorageConfig) => {
  const [files, setFiles] = useState<BlobFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [config, setConfig] = useState<BlobStorageConfig>(initialConfig);
  const [blobService, setBlobService] = useState<AzureBlobStorageService | null>(null);

  const loadFiles = useCallback(async (service: AzureBlobStorageService) => {
    try {
      setIsLoading(true);
      setError(null);
      const fileList = await service.listFiles();
      setFiles(fileList);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load files');
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Initialize blob service and load files when config changes
  useEffect(() => {
    if (config.sasToken && config.baseUrl && config.relativePath) {
      const service = new AzureBlobStorageService(config);
      setBlobService(service);
      loadFiles(service);
    }
  }, [config, loadFiles]);

  const uploadFile = async (file: File, progressCallback: (progress: number) => void) => {
    if (!blobService) return;

    try {
      setIsLoading(true);
      setError(null);
      await blobService.uploadFile(file, progressCallback);
      await loadFiles(blobService);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to upload file');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFile = async (path: string) => {
    if (!blobService) return;

    try {
      setIsLoading(true);
      setError(null);
      await blobService.deleteFile(path);
      await loadFiles(blobService);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete file');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const getFileUrl = useCallback(
    (path: string) => {
      if (!blobService) return '';
      return blobService.getFileUrl(path);
    },
    [blobService]
  );

  const navigateToDirectory = useCallback(
    (path: string) => {
      if (!blobService) return;
      blobService.navigateToDirectory(path);
      loadFiles(blobService);
    },
    [blobService, loadFiles]
  );

  const navigateUp = useCallback(() => {
    if (!blobService) return;
    blobService.navigateUp();
    loadFiles(blobService);
  }, [blobService, loadFiles]);

  const getCurrentPath = useCallback(() => {
    if (!blobService) return '';
    return blobService.getCurrentPath();
  }, [blobService]);

  const createDirectory = async (dirName: string) => {
    if (!blobService) return;

    try {
      setIsLoading(true);
      setError(null);
      await blobService.createDirectory(dirName);
      await loadFiles(blobService);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create directory');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    files,
    isLoading,
    error,
    uploadFile,
    deleteFile,
    getFileUrl,
    refreshFiles: () => blobService && loadFiles(blobService),
    navigateToDirectory,
    navigateUp,
    getCurrentPath,
    createDirectory,
    updateConfig: setConfig
  };
};
