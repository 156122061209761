import React, { useState } from 'react';
import { DocumentSearchOptions } from './types';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface DocumentSearchConfigProps {
  value: DocumentSearchOptions;
  onChange: (options: DocumentSearchOptions) => void;
}

const defaultFileTypes = ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'];
const defaultDocumentTypes = ['documentation', 'technical spec', 'user guide', 'manual'];

export function DocumentSearchConfig({ value, onChange }: DocumentSearchConfigProps) {
  const [newDocType, setNewDocType] = useState('');

  // Ensure we always have default values
  const currentValue = {
    count: value.count || 5,
    fileTypes: value.fileTypes?.length ? value.fileTypes : defaultFileTypes,
    documentTypes: value.documentTypes || []
  };

  const handleAddDocumentType = () => {
    if (newDocType && !currentValue.documentTypes?.includes(newDocType)) {
      onChange({
        ...currentValue,
        documentTypes: [...(currentValue.documentTypes || []), newDocType]
      });
      setNewDocType('');
    }
  };

  const handleRemoveDocumentType = (docType: string) => {
    onChange({
      ...currentValue,
      documentTypes: currentValue.documentTypes?.filter((t) => t !== docType) || []
    });
  };

  return (
    <div className="space-y-4">
      <h3 className="text-sm font-medium text-gray-900">Document Search Configuration</h3>
      <div>
        <label className="block text-sm font-medium text-gray-700">Document Types</label>
        <div className="mt-2">
          <div className="flex gap-2 mb-2">
            <input
              type="text"
              value={newDocType}
              onChange={(e) => setNewDocType(e.target.value)}
              placeholder="Enter document type..."
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddDocumentType();
                }
              }}
            />
            <button
              onClick={handleAddDocumentType}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PlusIcon className="h-4 w-4" />
            </button>
          </div>
          <div className="space-y-2">
            {currentValue.documentTypes?.map((docType) => (
              <div key={docType} className="flex items-center justify-between bg-gray-50 px-3 py-2 rounded-md">
                <span className="text-sm text-gray-700">{docType}</span>
                <button onClick={() => handleRemoveDocumentType(docType)} className="text-gray-400 hover:text-gray-500">
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            ))}
            {currentValue.documentTypes?.length === 0 && (
              <div className="text-sm text-gray-500 italic">
                No document types added. Add some above or select from suggestions below.
              </div>
            )}
            <div className="mt-4">
              <div className="text-xs text-gray-500 mb-2">Suggestions:</div>
              <div className="flex flex-wrap gap-2">
                {defaultDocumentTypes.map((docType) => (
                  <button
                    key={docType}
                    onClick={() => {
                      if (!currentValue.documentTypes?.includes(docType)) {
                        onChange({
                          ...currentValue,
                          documentTypes: [...(currentValue.documentTypes || []), docType]
                        });
                      }
                    }}
                    disabled={currentValue.documentTypes?.includes(docType)}
                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {docType}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
