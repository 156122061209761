export enum TagTypes {
  ProfileData = 'ProfileData',
  TenantData = 'TenantData',
  Invitations = 'Invitations',
  Users = 'Users',
  Roles = 'Roles',
  Subscriptions = 'Subscriptions',
  Workflows = 'Workflows',
  Datasets = 'Datasets',
  Connectors = 'Connectors',
  Connections = 'Connections',
  TenantSecrets = 'TenantSecrets',
  Files = 'Files'
}
