import { Outlet } from 'react-router-dom';
import { Navbar } from '../../components';

export const DashboardLayout = () => {
  return (
    <>
      <div className="flex flex-col h-full bg-white overflow-hidden">
        <Navbar />
        <div className="flex-grow w-full h-full overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
};
