import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import {
  ColumnIdentifier,
  EnrichmentDataTableHeader,
  EnrichmentGroup,
  EnrichmentRequestOptions,
  ImageSearchResponse,
  SourceEnrichmentGroupResult,
  SourceEnrichmentSummaries,
  SourceEnrichmentValues,
  WebPageSearchResult
} from './types';
import {
  createImagesOutput,
  createDocumentsOutput,
  createOutputData,
  createOutputHeader,
  remoteDownloadImagesZip,
  remoteDownloadDocumentsZip,
  saveCsv
} from './output';
import { convertToCsvString } from './csv';
import { enrichmentResultsReducer, EnrichmentResultState } from './enrichmentResultsReducer';
import { preloadImages } from './preload-images';
import { enrichmentClient } from './api';

export type ProductIdentifiersRow = {
  rowId: string;
  identifiers: ColumnIdentifier[];
};

export type EnrichAllOptions = {
  batchSize: number;
  fileName: string;
  rows: ProductIdentifiersRow[];
  options: EnrichmentRequestOptions;
};

export type ProductEnrichmentContextProps = {
  enrichmentResults: EnrichmentResultState;
  downloadLoading: boolean;
  enrichAllLoading: boolean;
  enrichAllStopped: boolean;
  skipEnriched: boolean;
  enrichProductRow: (fileName: string, rowId: string, options: EnrichmentRequestOptions) => Promise<void>;
  enrichAllProducts: (options: EnrichAllOptions) => Promise<void>;
  stopEnrichAll: () => void;
  toggleSkipEnriched: () => void;
  clearFileResults: (fileName: string) => void;
  clearRowResult: (fileName: string, rowId: string) => void;
  updateFieldValue: (fileName: string, rowId: string, groupName: string, fieldName: string, value: string) => void;
  regenerateField: (fileName: string, rowId: string, groupName: string, fieldName: string) => Promise<void>;
  downloadDetailedResults: (
    fileName: string,
    headers: EnrichmentDataTableHeader[],
    identifiers: ProductIdentifiersRow[]
  ) => Promise<void>;
  downloadSummary: (
    fileName: string,
    headers: EnrichmentDataTableHeader[],
    identifiers: ProductIdentifiersRow[]
  ) => Promise<void>;
  downloadImages: (fileName: string, identifiers: ProductIdentifiersRow[]) => Promise<void>;
  downloadDocuments: (fileName: string, identifiers: ProductIdentifiersRow[]) => Promise<void>;
  removeImage: (fileName: string, rowId: string, imageIndex: number) => void;
  regenerateImage: (
    fileName: string,
    rowId: string,
    imageIndex: number,
    options: EnrichmentRequestOptions
  ) => Promise<void>;
  regenerateAllImages: (fileName: string, rowId: string, options: EnrichmentRequestOptions) => Promise<void>;
  removeDocument: (fileName: string, rowId: string, documentId: string) => void;
  regenerateDocuments: (fileName: string, rowId: string, options: EnrichmentRequestOptions) => Promise<void>;
};

function initialiseEnrichmentResultState(): EnrichmentResultState {
  const jsonState = localStorage.getItem('enrichmentResults');
  if (!jsonState) {
    return {};
  }
  const result: EnrichmentResultState = {};

  const parsedState: EnrichmentResultState = JSON.parse(jsonState);
  Object.entries(parsedState).forEach(([fileName, importedData]) => {
    Object.entries(importedData).forEach(([rowId, row]) => {
      if (!result[fileName]) {
        result[fileName] = {
          [rowId]: {
            data: row.data,
            loading: false,
            error: row.error
          }
        };
      } else {
        result[fileName][rowId] = {
          data: row.data,
          loading: false,
          error: row.error
        };
      }
    });
  });

  return result;
}

function initialiseEnrichmentGroups(
  enrichmentGroups: EnrichmentGroup[],
  sourceLimit: number
): SourceEnrichmentGroupResult[] {
  return enrichmentGroups.map((group) => ({
    groupName: group.groupName,
    fields: group.fields.map((field) => ({
      fieldName: field.fieldName,
      kind: field.kind,
      values: Array.from({ length: sourceLimit }, (_, i) => ({
        value: undefined,
        sourceName: '',
        sourceUrl: ''
      }))
    }))
  }));
}

function extractValuesIntoEnrichmentGroupResults(
  enrichmentGroups: SourceEnrichmentGroupResult[],
  values: SourceEnrichmentValues,
  source: WebPageSearchResult,
  sourceIndex: number
) {
  return enrichmentGroups.map((group) => ({
    ...group,
    fields: group.fields.map((field) => ({
      ...field,
      values: field.values.map((enrichmentValue, i) => {
        if (i === sourceIndex) {
          return {
            value: values[field.fieldName],
            sourceName: source.name,
            sourceUrl: source.url
          };
        }
        return enrichmentValue;
      })
    }))
  }));
}

const ProductEnrichmentContext = createContext<ProductEnrichmentContextProps | undefined>(undefined);

export const ProductEnrichmentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [enrichmentResults, dispatchEnrichmentResults] = useReducer(
    enrichmentResultsReducer,
    {},
    initialiseEnrichmentResultState
  );
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [enrichAllLoading, setEnrichAllLoading] = useState(false);
  const enrichAllStoppedRef = useRef(false);
  const [enrichAllStopped, setEnrichAllStopped] = useState(false);
  const [skipEnriched, setSkipEnriched] = useState(true);

  useEffect(() => {
    document.title = 'Enrichment | Fireflai';
  });

  const enrichProductRow = useCallback(
    async (fileName: string, rowId: string, options: EnrichmentRequestOptions) => {
      const startTimestamp = Date.now();
      const enrichmentGroups = initialiseEnrichmentGroups(options.enrichmentGroups, options.sourceLimit);
      dispatchEnrichmentResults({
        type: 'START_PRODUCT_ENRICHMENT',
        payload: { fileName, rowId, enrichmentGroups }
      });

      // Create clean query from identifiers
      let cleanQuery: string;
      try {
        cleanQuery = await enrichmentClient.createCleanQuery({ identifiers: options.identifiers });
      } catch (err) {
        dispatchEnrichmentResults({
          type: 'SET_ENRICHMENT_ROW_ERROR',
          payload: { fileName, rowId, error: 'Failed to create clean query' }
        });
        return;
      }

      dispatchEnrichmentResults({
        type: 'UPSERT_ENRICHMENT_ROW_CLEAN_QUERY',
        payload: { fileName, rowId, cleanQuery }
      });

      let webSearchResults: WebPageSearchResult[];
      try {
        webSearchResults = await enrichmentClient.webSearch({
          query: cleanQuery,
          sources: options.sources,
          sourceBlacklist: options.sourceBlacklist,
          sourceLimit: options.sourceLimit
        });
      } catch (err) {
        dispatchEnrichmentResults({
          type: 'SET_ENRICHMENT_ROW_ERROR',
          payload: { fileName, rowId, error: 'Failed to find sources' }
        });
        return;
      }

      if (webSearchResults.length === 0) {
        dispatchEnrichmentResults({
          type: 'SET_ENRICHMENT_ROW_ERROR',
          payload: { fileName, rowId, error: 'Failed to find sources' }
        });
        return;
      }

      const cleanEnrichmentGroups = options.enrichmentGroups.map((group) => ({
        ...group,
        fields: group.fields.map((field) => ({ ...field, fieldName: field.fieldName.trim() }))
      }));

      let populatedEnrichmentGroups = enrichmentGroups;
      const webSourcedEnrichmentRequests = webSearchResults.map(async (webSearchResult, idx) => {
        // Scrape source web page
        let webPageText: string;
        try {
          webPageText = await enrichmentClient.scrapeWebPageText(webSearchResult.url);
        } catch (err) {
          return;
        }

        // Enrich fields
        let enrichedFields: SourceEnrichmentValues;
        try {
          enrichedFields = await enrichmentClient.enrichProductFields({
            identifiers: options.identifiers,
            enrichmentGroups: cleanEnrichmentGroups,
            sourceData: webPageText
          });
        } catch (err) {
          return;
        }

        if (Object.keys(enrichedFields).length === 0) {
          // No data enriched for this source
          return;
        }

        populatedEnrichmentGroups = extractValuesIntoEnrichmentGroupResults(
          populatedEnrichmentGroups,
          enrichedFields,
          webSearchResult,
          idx
        );

        dispatchEnrichmentResults({
          type: 'UPSERT_ENRICHMENT_ROW_RESULT_SOURCE_FIELDS',
          payload: {
            fileName,
            rowId,
            enrichmentGroups: populatedEnrichmentGroups
          }
        });

        return populatedEnrichmentGroups;
      });

      // Wait for all web sourced enrichment requests to complete
      await Promise.all(webSourcedEnrichmentRequests);

      // Search for related documents if options are provided
      if (options.documentSearchOptions) {
        try {
          const currentState = enrichmentResults[fileName]?.[rowId];
          const excludeDocuments = currentState?.data?.documentHistory?.map((doc) => doc.id) || [];

          const documentSearchResponse = await enrichmentClient.documentSearch({
            query: cleanQuery,
            ...options.documentSearchOptions,
            excludeDocuments
          });

          dispatchEnrichmentResults({
            type: 'UPSERT_ENRICHMENT_ROW_RESULT_DOCUMENTS',
            payload: { fileName, rowId, documents: documentSearchResponse.documents }
          });
        } catch (error) {
          // Don't fail the process for documents, just show no results
          dispatchEnrichmentResults({
            type: 'UPSERT_ENRICHMENT_ROW_RESULT_DOCUMENTS',
            payload: { fileName, rowId, documents: [] }
          });
        }
      }

      let imagesResult: ImageSearchResponse;
      try {
        const currentState = enrichmentResults[fileName]?.[rowId];
        const excludeImages = currentState?.data?.imageHistory?.map((img) => img.contentUrl) || [];

        imagesResult = await enrichmentClient.searchImages(cleanQuery, {
          ...options.imageSearchOptions,
          excludeImages
        });
        dispatchEnrichmentResults({
          type: 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES',
          payload: { fileName, rowId, images: imagesResult.images || [] }
        });
        preloadImages(imagesResult.images);
      } catch (err) {
        // Don't fail the process for images, just show no results
        dispatchEnrichmentResults({
          type: 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES',
          payload: { fileName, rowId, images: [] }
        });
      }

      // Result summaries
      let resultSummaries: SourceEnrichmentSummaries;
      try {
        resultSummaries = await enrichmentClient.summariseEnrichmentGroupResult({
          results: populatedEnrichmentGroups
        });
        dispatchEnrichmentResults({ type: 'UPSERT_RESULT_SUMMARIES', payload: { fileName, rowId, resultSummaries } });
      } catch (err) {
        // Don't fail process for summaries, leave the summaries empty
      }

      const duration = Math.round((Date.now() - startTimestamp) / 100) / 10;

      dispatchEnrichmentResults({
        type: 'COMPLETE_PRODUCT_ENRICHMENT',
        payload: { fileName, rowId, duration }
      });
    },
    [enrichmentResults]
  );

  const downloadSummary = useCallback(
    async (fileName: string, headers: EnrichmentDataTableHeader[], identifiers: ProductIdentifiersRow[]) => {
      if (Object.values(enrichmentResults).length === 0) {
        throw new Error(`no data to download`);
      }

      setDownloadLoading(true);

      const identifierHeaderNames: string[] = [];
      const identifierIndices: number[] = [];
      headers.forEach((header, index) => {
        if (header.kind === 'identifier') {
          identifierHeaderNames.push(header.name);
          identifierIndices.push(index);
        }
      });

      const { dataHeader, summaryDataHeader } = createOutputHeader(identifierHeaderNames, enrichmentResults[fileName]);

      const results = identifiers.map((ident) => {
        const rowEnrichmentResults = enrichmentResults[fileName][ident.rowId];
        if (!!rowEnrichmentResults) {
          return rowEnrichmentResults;
        }
        return null;
      });

      const output = createOutputData(identifiers, results, dataHeader.length);

      const outputSummaryCsv = convertToCsvString(summaryDataHeader, output.summaryData);
      saveCsv('summary.csv', outputSummaryCsv);

      setDownloadLoading(false);
    },
    [enrichmentResults]
  );

  const downloadDetailedResults = useCallback(
    async (fileName: string, headers: EnrichmentDataTableHeader[], identifiers: ProductIdentifiersRow[]) => {
      if (Object.values(enrichmentResults).length === 0) {
        throw new Error(`no data to download`);
      }

      setDownloadLoading(true);

      const identifierHeaderNames: string[] = [];
      const identifierIndices: number[] = [];
      headers.forEach((header, index) => {
        if (header.kind === 'identifier') {
          identifierHeaderNames.push(header.name);
          identifierIndices.push(index);
        }
      });

      const { dataHeader } = createOutputHeader(identifierHeaderNames, enrichmentResults[fileName]);

      const results = identifiers.map((ident) => {
        const rowEnrichmentResults = enrichmentResults[fileName][ident.rowId];
        if (!!rowEnrichmentResults) {
          return rowEnrichmentResults;
        }
        return null;
      });

      const output = createOutputData(identifiers, results, dataHeader.length);

      const outputCsv = convertToCsvString(dataHeader, output.data);
      saveCsv('detailed.csv', outputCsv);

      setDownloadLoading(false);
    },
    [enrichmentResults]
  );

  const downloadImages = useCallback(
    async (fileName: string, identifiers: ProductIdentifiersRow[]) => {
      if (Object.values(enrichmentResults).length === 0) {
        throw new Error(`no data to download`);
      }

      setDownloadLoading(true);

      const output = createImagesOutput(identifiers, enrichmentResults[fileName]);

      await remoteDownloadImagesZip(output);

      setDownloadLoading(false);
    },
    [enrichmentResults]
  );

  const downloadDocuments = useCallback(
    async (fileName: string, identifiers: ProductIdentifiersRow[]) => {
      if (Object.values(enrichmentResults).length === 0) {
        throw new Error(`no data to download`);
      }

      setDownloadLoading(true);

      const output = createDocumentsOutput(identifiers, enrichmentResults[fileName]);

      await remoteDownloadDocumentsZip(output);

      setDownloadLoading(false);
    },
    [enrichmentResults]
  );

  const enrichAllProducts = useCallback(
    async (options: EnrichAllOptions) => {
      setEnrichAllLoading(true);

      const { batchSize, fileName, rows, options: enrichmentOptions } = options;

      // Track the current index of rows being processed
      let currentIndex = 0;

      const nextTask: () => Promise<void> = async () => {
        // If all rows have been processed, stop
        if (currentIndex >= rows.length || enrichAllStoppedRef.current) {
          return;
        }

        // Get the next row to process
        const row = rows[currentIndex];
        currentIndex++;

        // Skip rows already enriched if applicable
        if (skipEnriched && enrichmentResults[fileName]?.[row.rowId]?.data) {
          // Move to the next task immediately
          return nextTask();
        }

        // Process the current row
        try {
          await enrichProductRow(fileName, row.rowId, {
            ...enrichmentOptions,
            identifiers: row.identifiers
          });
        } finally {
          // Start a new task as this one finishes
          await nextTask();
        }
      };

      // Initialize workers
      const workers: Promise<void>[] = [];
      for (let i = 0; i < Math.min(batchSize, rows.length); i++) {
        workers.push(nextTask());
      }

      // Wait for all workers to complete
      await Promise.all(workers);

      // Reset the stop flag
      if (enrichAllStoppedRef.current) {
        enrichAllStoppedRef.current = false;
        setEnrichAllStopped(false);
      }

      setEnrichAllLoading(false);
    },
    [enrichProductRow, skipEnriched, enrichmentResults]
  );

  const stopEnrichAll = useCallback(() => {
    enrichAllStoppedRef.current = true;
    setEnrichAllStopped(true);
  }, []);

  const toggleSkipEnriched = useCallback(() => {
    setSkipEnriched((prev) => !prev);
  }, []);

  const clearFileResults = useCallback((fileName: string) => {
    dispatchEnrichmentResults({ type: 'CLEAR_ENRICHMENT_FILE_RESULTS', payload: { fileName } });
  }, []);

  const clearRowResult = useCallback((fileName: string, rowId: string) => {
    dispatchEnrichmentResults({ type: 'CLEAR_ENRICHMENT_ROW_RESULT', payload: { fileName, rowId } });
  }, []);

  const updateFieldValue = useCallback(
    (fileName: string, rowId: string, groupName: string, fieldName: string, value: string) => {
      dispatchEnrichmentResults({
        type: 'UPDATE_ENRICHMENT_FIELD_VALUE',
        payload: { fileName, rowId, groupName, fieldName, value }
      });
    },
    []
  );

  const regenerateField = useCallback(
    async (fileName: string, rowId: string, groupName: string, fieldName: string) => {
      const currentState = enrichmentResults[fileName]?.[rowId];
      if (!currentState?.data) return;

      const group = currentState.data.groups.find((g) => g.groupName === groupName);
      if (!group) return;

      const field = group.fields.find((f) => f.fieldName === fieldName);
      if (!field) return;

      // Create a new enrichment group with just this field
      const singleFieldGroup: EnrichmentGroup = {
        groupName,
        fields: [{ fieldName, kind: field.kind }]
      };

      // Get the current options from the state
      const cleanQuery = currentState.data.cleanQuery;
      if (!cleanQuery) return;

      // Start loading state for this field
      dispatchEnrichmentResults({
        type: 'START_PRODUCT_ENRICHMENT',
        payload: {
          fileName,
          rowId,
          enrichmentGroups: [
            {
              groupName,
              fields: [
                {
                  fieldName,
                  kind: field.kind,
                  values: field.values
                }
              ]
            }
          ]
        }
      });

      try {
        // Search web for sources
        const webSearchResults = await enrichmentClient.webSearch({
          query: cleanQuery,
          sources: [], // Use default sources
          sourceBlacklist: [],
          sourceLimit: 1
        });

        if (webSearchResults.length === 0) {
          throw new Error('No sources found');
        }

        // Scrape the first source
        const webPageText = await enrichmentClient.scrapeWebPageText(webSearchResults[0].url);

        // Enrich just this field
        const enrichedFields = await enrichmentClient.enrichProductFields({
          identifiers: [], // Use existing context
          enrichmentGroups: [singleFieldGroup],
          sourceData: webPageText
        });

        if (!enrichedFields[fieldName]) {
          throw new Error('Failed to enrich field');
        }

        // Update just this field's value
        updateFieldValue(fileName, rowId, groupName, fieldName, enrichedFields[fieldName].toString());
      } catch (error) {
        dispatchEnrichmentResults({
          type: 'SET_ENRICHMENT_ROW_ERROR',
          payload: {
            fileName,
            rowId,
            error: `Failed to regenerate field ${fieldName}: ${error}`
          }
        });
      }
    },
    [enrichmentResults, updateFieldValue]
  );

  const removeImage = useCallback((fileName: string, rowId: string, imageIndex: number) => {
    dispatchEnrichmentResults({
      type: 'REMOVE_ENRICHMENT_ROW_IMAGE',
      payload: { fileName, rowId, imageIndex }
    });
  }, []);

  const regenerateImage = useCallback(
    async (fileName: string, rowId: string, imageIndex: number, options: EnrichmentRequestOptions) => {
      const currentState = enrichmentResults[fileName]?.[rowId];
      if (!currentState?.data?.cleanQuery) return;

      try {
        // Get URLs of all images we want to exclude
        const excludeImages = [
          ...(currentState.data.images || []).map((img) => img.contentUrl),
          ...(currentState.data.imageHistory || []).map((img) => img.contentUrl)
        ];

        const imagesResult = await enrichmentClient.searchImages(currentState.data.cleanQuery, {
          ...options.imageSearchOptions,
          count: 1, // Only get one image for replacement
          excludeImages
        });

        if (imagesResult.images && imagesResult.images.length > 0) {
          const currentImages = [...(currentState.data.images || [])];
          currentImages[imageIndex] = imagesResult.images[0];

          dispatchEnrichmentResults({
            type: 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES',
            payload: { fileName, rowId, images: currentImages }
          });

          preloadImages([imagesResult.images[0]]);
        }
      } catch (err) {
        // Handle error silently as it's a user-initiated action
        // The error will be visible in the UI through the regeneration button state
      }
    },
    [enrichmentResults]
  );

  const regenerateAllImages = useCallback(
    async (fileName: string, rowId: string, options: EnrichmentRequestOptions) => {
      const currentState = enrichmentResults[fileName]?.[rowId];
      if (!currentState?.data?.cleanQuery) return;

      try {
        // Get URLs of all images we want to exclude
        const excludeImages = (currentState.data.imageHistory || []).map((img) => img.contentUrl);

        const imagesResult = await enrichmentClient.searchImages(currentState.data.cleanQuery, {
          ...options.imageSearchOptions,
          excludeImages
        });

        if (imagesResult.images && imagesResult.images.length > 0) {
          dispatchEnrichmentResults({
            type: 'UPSERT_ENRICHMENT_ROW_RESULT_IMAGES',
            payload: { fileName, rowId, images: imagesResult.images }
          });

          preloadImages(imagesResult.images);
        }
      } catch (err) {
        // Handle error silently as it's a user-initiated action
        // The error will be visible in the UI through the regeneration button state
      }
    },
    [enrichmentResults]
  );

  const removeDocument = useCallback((fileName: string, rowId: string, documentId: string) => {
    dispatchEnrichmentResults({
      type: 'REMOVE_ENRICHMENT_ROW_DOCUMENT',
      payload: { fileName, rowId, documentId }
    });
  }, []);

  const regenerateDocuments = useCallback(
    async (fileName: string, rowId: string, options: EnrichmentRequestOptions) => {
      try {
        const cleanQuery = enrichmentResults[fileName][rowId].data?.cleanQuery;
        if (!cleanQuery) {
          throw new Error('No clean query available');
        }

        const documentSearchResponse = await enrichmentClient.documentSearch({
          query: cleanQuery,
          ...options.documentSearchOptions
        });

        dispatchEnrichmentResults({
          type: 'UPSERT_ENRICHMENT_ROW_RESULT_DOCUMENTS',
          payload: { fileName, rowId, documents: documentSearchResponse.documents }
        });
      } catch (error) {
        // Handle error silently as it's a user-initiated action
        // The error will be visible in the UI through the regeneration button state
      }
    },
    [enrichmentResults]
  );

  useEffect(() => {
    // Uncomment to store results in local storage - limited to 10mb
    // localStorage.setItem('enrichmentResults', JSON.stringify(enrichmentResults));
  }, [enrichmentResults]);

  useEffect(() => {
    return () => {
      setEnrichAllStopped(false);
    };
  }, []);

  return (
    <ProductEnrichmentContext.Provider
      value={{
        enrichmentResults,
        downloadLoading,
        enrichAllLoading,
        enrichAllStopped,
        skipEnriched,
        enrichProductRow,
        enrichAllProducts,
        stopEnrichAll,
        toggleSkipEnriched,
        clearFileResults,
        clearRowResult,
        updateFieldValue,
        regenerateField,
        downloadDetailedResults,
        downloadSummary,
        downloadImages,
        downloadDocuments,
        removeImage,
        regenerateImage,
        regenerateAllImages,
        removeDocument,
        regenerateDocuments
      }}
    >
      {children}
    </ProductEnrichmentContext.Provider>
  );
};

export const useProductEnrichment = (): ProductEnrichmentContextProps => {
  const context = useContext(ProductEnrichmentContext);
  if (context === undefined) {
    throw new Error('useImportedData must be used within an ImportedDataProvider');
  }
  return context;
};

export const useActiveFileEnrichmentResults = (fileName?: string) => {
  const { enrichmentResults } = useProductEnrichment();

  if (!fileName) {
    return;
  }

  return enrichmentResults[fileName];
};
