import { useEffect, useState } from 'react';
import { MDMProject } from '../types';
import { SectionHeader } from '../components/SectionHeader';
import { MDMProjectCard } from '../components/MDMProjectCard';
import { apiClient } from '../api';

export function MDMProjectListPage() {
  const [mdmProjects, setMdmProjects] = useState<MDMProject[]>([]); // Put the static values here

  useEffect(() => {
    document.title = 'MDM | Fireflai';
  });
  useEffect(() => {
    apiClient.getProjects().then((projects) => {
      setMdmProjects(projects);
    });
  }, []);

  return (
    <div className="h-full flex flex-col p-8">
      <SectionHeader title="MDM Projects" subtitle="Manage MDM projects and review results" />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-8">
        {mdmProjects.map((project) => {
          return <MDMProjectCard key={project.id} project={project} />;
        })}
      </div>
    </div>
  );
}
