import { env } from '../../env';
import {
  MDMProject,
  MDMProjectResult,
  MDMProjectResultReviewDetail,
  MDMProjectResultReviewStrategy,
  MDMProjectResultReviewStrategySchema,
  MDMProjectResultReviewWithRecord,
  MDMProjectResultReviewWithRecordSchema,
  MDMProjectResultSchema,
  MDMProjectReviewStrategy,
  MDMProjectReviewStrategySchema,
  MDMProjectSchema
} from './types';

export class APIClient {
  constructor(protected baseUrl: string) {}

  getProjects = async (): Promise<Array<MDMProject>> => {
    const response = await fetch(`${this.baseUrl}/project`);
    return MDMProjectSchema.array().parse(await response.json());
  };

  getProject = async (id: number): Promise<MDMProject> => {
    const response = await fetch(`${this.baseUrl}/project/${id}`);
    return MDMProjectSchema.parse(await response.json());
  };

  getProjectReviewStrategies = async (projectId: number): Promise<Array<MDMProjectReviewStrategy>> => {
    const response = await fetch(`${this.baseUrl}/project/${projectId}/strategy`);
    return MDMProjectReviewStrategySchema.array().parse(await response.json());
  };

  getProjectResults = async (projectId: number): Promise<Array<MDMProjectResult>> => {
    const response = await fetch(`${this.baseUrl}/project/${projectId}/result`);
    return MDMProjectResultSchema.array().parse(await response.json());
  };

  getProjectResultReviewStrategies = async (
    projectId: number,
    resultId: number
  ): Promise<Array<MDMProjectResultReviewStrategy>> => {
    const response = await fetch(`${this.baseUrl}/project/${projectId}/result/${resultId}/strategy`);
    return MDMProjectResultReviewStrategySchema.array().parse(await response.json());
  };

  getProjectResultReviewStrategy = async (
    projectId: number,
    resultId: number,
    strategyId: number
  ): Promise<MDMProjectResultReviewStrategy> => {
    const response = await fetch(`${this.baseUrl}/project/${projectId}/result/${resultId}/strategy/${strategyId}`);
    return MDMProjectResultReviewStrategySchema.parse(await response.json());
  };

  getNextReviewRecord = async (
    projectId: number,
    resultId: number,
    resultReviewStrategyId: number
  ): Promise<MDMProjectResultReviewWithRecord | null> => {
    const response = await fetch(
      `${this.baseUrl}/project/${projectId}/result/${resultId}/strategy/${resultReviewStrategyId}/review/next`
    );
    return MDMProjectResultReviewWithRecordSchema.nullable().parse(await response.json());
  };

  addReview = async (
    projectId: number,
    resultId: number,
    resultReviewStrategyId: number,
    reviewId: number,
    review: MDMProjectResultReviewDetail
  ): Promise<void> => {
    await fetch(
      `${this.baseUrl}/project/${projectId}/result/${resultId}/strategy/${resultReviewStrategyId}/review/${reviewId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(review)
      }
    );
  };
}

export const apiClient = new APIClient(env.REACT_APP_MDM_REVIEW_API_FQDN);
