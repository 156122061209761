import { useState, useEffect } from 'react';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { SavedEnrichmentTemplate } from './types';

interface SavedTemplatesPanelProps {
  onClose: () => void;
}

export function SavedTemplatesPanel({ onClose }: SavedTemplatesPanelProps) {
  const { savedTemplates, saveCurrentTemplate, updateTemplate, deleteTemplate, loadTemplate } = useEnrichmentConfig();

  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [templateToDelete, setTemplateToDelete] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState<SavedEnrichmentTemplate[]>(savedTemplates);

  // Filter templates based on search query
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredTemplates(savedTemplates);
    } else {
      const query = searchQuery.toLowerCase();
      setFilteredTemplates(
        savedTemplates.filter(
          (template) =>
            template.name.toLowerCase().includes(query) || template.description.toLowerCase().includes(query)
        )
      );
    }
  }, [searchQuery, savedTemplates]);

  const handleSaveTemplate = () => {
    if (templateName.trim() === '') return;

    saveCurrentTemplate(templateName, templateDescription);
    setTemplateName('');
    setTemplateDescription('');
    setIsCreating(false);
  };

  const handleUpdateTemplate = () => {
    if (!isEditing || templateName.trim() === '') return;

    const template = savedTemplates.find((t) => t.id === isEditing);
    if (!template) return;

    updateTemplate(isEditing, {
      name: templateName,
      description: templateDescription,
      updatedAt: new Date().toISOString()
    });

    setTemplateName('');
    setTemplateDescription('');
    setIsEditing(null);
  };

  const handleEditTemplate = (template: SavedEnrichmentTemplate) => {
    setTemplateName(template.name);
    setTemplateDescription(template.description);
    setIsEditing(template.id);
    setIsCreating(false);
  };

  const handleLoadTemplate = (templateId: string) => {
    loadTemplate(templateId);
    onClose();
  };

  const handleConfirmDelete = (templateId: string) => {
    setTemplateToDelete(templateId);
  };

  const handleDeleteTemplate = () => {
    if (templateToDelete) {
      deleteTemplate(templateToDelete);
      setTemplateToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setTemplateToDelete(null);
  };

  const handleCreateNew = () => {
    setIsCreating(true);
    setIsEditing(null);
    setTemplateName('');
    setTemplateDescription('');
  };

  const handleCancel = () => {
    setIsCreating(false);
    setIsEditing(null);
    setTemplateName('');
    setTemplateDescription('');
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const countFields = (template: SavedEnrichmentTemplate) => {
    return template.groups.reduce((total: number, group) => total + group.fields.length, 0);
  };

  return (
    <div className="w-full">
      {/* Header with back button */}
      <div className="flex items-center mb-4">
        <button onClick={onClose} className="mr-2 p-1 rounded-full hover:bg-gray-100" title="Back to editor">
          <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
        </button>
        <h3 className="text-lg font-medium">Saved Templates</h3>
      </div>

      {/* Search and Create New */}
      <div className="flex items-center mb-4 gap-2">
        <div className="relative flex-grow">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search templates..."
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          onClick={handleCreateNew}
          className="px-3 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Create New
        </button>
      </div>

      {/* Create/Edit Form */}
      {(isCreating || isEditing) && (
        <div className="mb-6 p-4 border border-gray-200 rounded-md bg-gray-50">
          <h4 className="text-md font-medium mb-3">{isEditing ? 'Edit Template' : 'Create New Template'}</h4>
          <div className="mb-3">
            <label htmlFor="templateName" className="block text-sm font-medium text-gray-700 mb-1">
              Template Name
            </label>
            <input
              id="templateName"
              type="text"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Enter template name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="templateDescription" className="block text-sm font-medium text-gray-700 mb-1">
              Description (optional)
            </label>
            <textarea
              id="templateDescription"
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Enter description"
              rows={2}
              value={templateDescription}
              onChange={(e) => setTemplateDescription(e.target.value)}
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              onClick={handleCancel}
              className="px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={isEditing ? handleUpdateTemplate : handleSaveTemplate}
              className="px-3 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={templateName.trim() === ''}
            >
              {isEditing ? 'Update' : 'Save'}
            </button>
          </div>
        </div>
      )}

      {/* Delete Confirmation */}
      {templateToDelete && (
        <div className="mb-6 p-4 border border-red-200 rounded-md bg-red-50">
          <h4 className="text-md font-medium mb-2 text-red-700">Confirm Delete</h4>
          <p className="text-sm text-red-600 mb-4">
            Are you sure you want to delete this template? This action cannot be undone.
          </p>
          <div className="flex justify-end gap-2">
            <button
              onClick={handleCancelDelete}
              className="px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteTemplate}
              className="px-3 py-2 bg-red-600 text-white rounded-md text-sm font-medium hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      )}

      {/* Templates List */}
      {filteredTemplates.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500 mb-2">
            {searchQuery ? 'No templates match your search' : 'No templates saved yet'}
          </p>
          {searchQuery && (
            <button onClick={() => setSearchQuery('')} className="text-blue-600 hover:text-blue-800 text-sm">
              Clear search
            </button>
          )}
          {!searchQuery && !isCreating && (
            <button onClick={handleCreateNew} className="text-blue-600 hover:text-blue-800 text-sm">
              Create your first template
            </button>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          {filteredTemplates.map((template) => (
            <div key={template.id} className="border border-gray-200 rounded-md p-4 hover:shadow-sm transition-shadow">
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-medium text-gray-900">{template.name}</h4>
                  {template.description && <p className="text-sm text-gray-500 mt-1">{template.description}</p>}
                  <div className="mt-2 flex items-center text-xs text-gray-500 space-x-4">
                    <span>{template.groups.length} groups</span>
                    <span>{countFields(template)} fields</span>
                    <span>Updated: {formatDate(template.updatedAt)}</span>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleLoadTemplate(template.id)}
                    className="px-3 py-1.5 bg-blue-600 text-white rounded text-sm hover:bg-blue-700"
                    title="Load template"
                  >
                    Load
                  </button>
                  <button
                    onClick={() => handleEditTemplate(template)}
                    className="px-3 py-1.5 border border-gray-300 bg-white text-gray-700 rounded text-sm hover:bg-gray-50"
                    title="Edit template"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleConfirmDelete(template.id)}
                    className="px-3 py-1.5 border border-gray-300 bg-white text-gray-700 rounded text-sm hover:bg-gray-50 hover:text-red-600"
                    title="Delete template"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
