import { XMarkIcon } from '@heroicons/react/24/outline';
import { FileUpload } from './FileUpload';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onFileAdd: (files: File[]) => void;
  isUploading?: boolean;
  onCancel?: () => void;
};

export function FileUploadModal({ isOpen, onClose, onFileAdd, isUploading, onCancel }: Props) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-medium text-gray-900">Upload Files</h3>
              <p className="mt-1 text-sm text-gray-500">
                Upload files to your workspace. You can drag and drop files or click to browse.
              </p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        <div className="p-6">
          <FileUpload onFileAdd={onFileAdd} isUploading={isUploading} onCancel={onCancel} />
        </div>
      </div>
    </div>
  );
}
